import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from './lms/notification/notification/notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  refreshToken: string;
  submitClicked: boolean = false;
  childModal: any;
  session: any;
  keycloakAuth: any;

  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnInit() {
    // let path: string = window.location.href;
    // console.info(path);
    // if (path.startsWith('https') || path.startsWith('http://localhost:4200')) {
    //   this.notificationService.initListener();
    //   setTimeout(() => {
    //     this.testMessaging();
    //   }, 2000);
    // } else {
    //   console.warn('Domain is not https, firebase messaging utility disabled');
    // }
  }

  async testMessaging() {
    // await this.notificationService.testMessaging().toPromise();
  }
}
