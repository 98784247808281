export enum CONTEXTABLE_TYPE {
  INDIVIDUAL,
  PERSON,
  COMPANY,
  ORGANIZATION,
  DEAL,
}

export enum leadType{
  INDIVIDUAL,
  COMPANY
}

// export enum MONTHS_OF_YEAR {
//   JANUARY,
//   FEBRUARY,
//   MARCH,
//   APRIL,
//   MAY,
//   JUNE,
//   JULY,
//   AUGUST,
//   SEPTEMBER,
//   OCTOBER,
//   NOVEMBER,
//   DECEMBER,
// }

export enum MONTHS_OF_YEAR {
  JANUARY = '0',
  FEBRUARY = '1',
  MARCH = '2',
  APRIL = '3',
  MAY = '4',
  JUNE = '5',
  JULY = '6',
  AUGUST = '7',
  SEPTEMBER = '8',
  OCTOBER = '9',
  NOVEMBER = '10',
  DECEMBER = '11',
}

export enum DEAL_STATUS {
  OPEN,
  WON,
  LOST,
  DORMANT,
  PENDING_REASSIGN,
  PENDING_UPDATE,
  PENDING_LOST,
  PENDING_WON,
  PENDING_OPEN,
  PENDING_DELETE,
  PENDING_DORMANT,
}

export enum MARKETING_TYPE {
  CHANNEL,
  CAMPAIGN,
}

export enum LEAD_TYPE {
  INDIVIDUAL,
  COMPANY,
}

export enum GENDER {
  MALE,
  FEMALE,
}

export enum ID_TYPE {
  IC_NO,
  MILITARY,
  POLICE,
  PASSPORT,
}

export enum COMPANY_TYPE {
  LIMITED,
  SOLE_SSM,
  SOLE_EAST,
  PARTNERSHIP_SSM,
  PARTNERSHIP_EAST,
  PRIVATE,
  PUBLIC,
}

export enum CLOSE_TYPE {
  LOST,
  WON,
}

export enum SECTOR {
  MANUFACTURING,
  SERVICES,
}

export enum ACTIVITYSTATUS {
  TODO = 'status_todo',
  DONE = 'status_done',
}

export enum DAYS_OF_WEEK {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum SALUTATION {
  MISS,
  MRS,
  MR,
  TAN_SRI,
  TUN,
  DATO_SERI,
  DATUK_SERI,
  DATO,
  DATIN,
  DATUK,
  DR,
  PROF,
  TS,
}

export enum SETTINGS_CONTEXT {
  MAIL = 'mail',
  USER = 'user',
  BRANCH = 'branch',
}

export enum JOBGROUPTYPE {
  SYNCHRONIZATION = 'SYNCHRONIZATION',
  TECHNICIAN = 'TECHNICIAN',
}

export enum ENABLEDJOBSCHEDULER {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum STATUSJOBSCHEDULER {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING_UPDATE = 'PENDING_UPDATE',
}
