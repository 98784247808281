export const PERMISSIONS_DATA = {
  ROLE_ACTIVITY_APPOINTMENT_SETUP_VIEW: 'ROLE_ACTIVITY_APPOINTMENT_SETUP_VIEW',
  ROLE_ACTIVITY_APPOINTMENT_SETUP_CREATE: 'ROLE_ACTIVITY_APPOINTMENT_SETUP_CREATE',
  ROLE_ACTIVITY_APPOINTMENT_SETUP_UPDATE: 'ROLE_ACTIVITY_APPOINTMENT_SETUP_UPDATE',
  ROLE_ACTIVITY_APPOINTMENT_SETUP_DELETE: 'ROLE_ACTIVITY_APPOINTMENT_SETUP_DELETE',

  ROLE_ACTIVITY_VIEW: 'ROLE_ACTIVITY_VIEW',
  ROLE_ACTIVITY_CREATE: 'ROLE_ACTIVITY_CREATE',
  ROLE_ACTIVITY_UPDATE: 'ROLE_ACTIVITY_UPDATE',
  ROLE_ACTIVITY_DELETE: 'ROLE_ACTIVITY_DELETE',

  ROLE_AUDIT_VIEW: 'ROLE_AUDIT_VIEW',

  ROLE_BRANCH_VIEW: 'ROLE_BRANCH_VIEW',
  ROLE_BRANCH_CREATE: 'ROLE_BRANCH_CREATE',
  ROLE_BRANCH_CREATE_APPROVE: 'ROLE_BRANCH_CREATE_APPROVE',
  ROLE_BRANCH_UPDATE: 'ROLE_BRANCH_UPDATE',
  ROLE_BRANCH_UPDATE_APPROVE: 'ROLE_BRANCH_UPDATE_APPROVE',
  ROLE_BRANCH_DELETE: 'ROLE_BRANCH_DELETE',
  ROLE_BRANCH_DELETE_APPROVE: 'ROLE_BRANCH_DELETE_APPROVE',

  ROLE_CHANNEL_SETUP_VIEW: 'ROLE_CHANNEL_SETUP_VIEW',
  ROLE_CHANNEL_SETUP_CREATE: 'ROLE_CHANNEL_SETUP_CREATE',
  ROLE_CHANNEL_SETUP_UPDATE: 'ROLE_CHANNEL_SETUP_UPDATE',
  ROLE_CHANNEL_SETUP_UPDATE_APPROVE: 'ROLE_CHANNEL_SETUP_UPDATE_APPROVE',

  ROLE_CHANNEL_VIEW: 'ROLE_CHANNEL_VIEW',
  ROLE_CHANNEL_CREATE: 'ROLE_CHANNEL_CREATE',
  ROLE_CHANNEL_UPDATE: 'ROLE_CHANNEL_UPDATE',
  ROLE_CHANNEL_DELETE: 'ROLE_CHANNEL_DELETE',
  ROLE_CHANNEL_CREATE_APPROVE: 'ROLE_CHANNEL_CREATE_APPROVE',
  ROLE_CHANNEL_UPDATE_APPROVE: 'ROLE_CHANNEL_UPDATE_APPROVE',
  ROLE_CHANNEL_DELETE_APPROVE: 'ROLE_CHANNEL_DELETE_APPROVE',

  ROLE_CAMPAIGN_VIEW: 'ROLE_CAMPAIGN_VIEW',
  ROLE_CAMPAIGN_CREATE: 'ROLE_CAMPAIGN_CREATE',
  ROLE_CAMPAIGN_UPDATE: 'ROLE_CAMPAIGN_UPDATE',
  ROLE_CAMPAIGN_DELETE: 'ROLE_CAMPAIGN_DELETE',
  ROLE_CAMPAIGN_CREATE_APPROVE: 'ROLE_CAMPAIGN_CREATE_APPROVE',
  ROLE_CAMPAIGN_UPDATE_APPROVE: 'ROLE_CAMPAIGN_UPDATE_APPROVE',
  ROLE_CAMPAIGN_DELETE_APPROVE: 'ROLE_CAMPAIGN_DELETE_APPROVE',

  ROLE_CRITERIA_VIEW: 'ROLE_CRITERIA_VIEW',
  ROLE_CRITERIA_CREATE: 'ROLE_CRITERIA_CREATE',
  ROLE_CRITERIA_CREATE_APPROVE: 'ROLE_CRITERIA_CREATE_APPROVE',
  ROLE_CRITERIA_UPDATE: 'ROLE_CRITERIA_UPDATE',
  ROLE_CRITERIA_UPDATE_APPROVE: 'ROLE_CRITERIA_UPDATE_APPROVE',
  ROLE_CRITERIA_DELETE: 'ROLE_CRITERIA_DELETE',

  ROLE_DASHBOARD_VIEW: 'ROLE_DASHBOARD_VIEW',

  ROLE_DEAL_VIEW: 'ROLE_DEAL_VIEW',
  ROLE_DEAL_CREATE: 'ROLE_DEAL_CREATE',
  ROLE_DEAL_UPDATE: 'ROLE_DEAL_UPDATE',
  ROLE_DEAL_DELETE: 'ROLE_DEAL_DELETE',
  ROLE_DEAL_DELETE_APPROVE: 'ROLE_DEAL_DELETE_APPROVE',
  ROLE_DEAL_REASSIGN: 'ROLE_DEAL_REASSIGN',
  ROLE_DEAL_REASSIGN_APPROVE: 'ROLE_DEAL_REASSIGN_APPROVE',
  ROLE_DEAL_OPEN: 'ROLE_DEAL_OPEN',
  ROLE_DEAL_OPEN_APPROVE: 'ROLE_DEAL_OPEN_APPROVE',
  ROLE_DEAL_WON: 'ROLE_DEAL_WON',
  ROLE_DEAL_WON_APPROVE: 'ROLE_DEAL_WON_APPROVE',
  ROLE_DEAL_LOST: 'ROLE_DEAL_LOST',
  ROLE_DEAL_LOST_APPROVE: 'ROLE_DEAL_LOST_APPROVE',
  ROLE_DEAL_DORMANT: 'ROLE_DEAL_DORMANT',
  ROLE_DEAL_DORMANT_APPROVE: 'ROLE_DEAL_DORMANT_APPROVE',

  ROLE_DISTRIBUTION_GROUP_VIEW: 'ROLE_DISTRIBUTION_GROUP_VIEW',
  ROLE_DISTRIBUTION_GROUP_CREATE: 'ROLE_DISTRIBUTION_GROUP_CREATE',
  ROLE_DISTRIBUTION_GROUP_CREATE_APPROVE: 'ROLE_DISTRIBUTION_GROUP_CREATE_APPROVE',
  ROLE_DISTRIBUTION_GROUP_UPDATE: 'ROLE_DISTRIBUTION_GROUP_UPDATE',
  ROLE_DISTRIBUTION_GROUP_UPDATE_APPROVE: 'ROLE_DISTRIBUTION_GROUP_UPDATE_APPROVE',

  ROLE_FILE_VIEW: 'ROLE_FILE_VIEW',
  ROLE_FILE_CREATE: 'ROLE_FILE_CREATE',
  ROLE_FILE_UPDATE: 'ROLE_FILE_UPDATE',
  ROLE_FILE_DELETE: 'ROLE_FILE_DELETE',

  ROLE_FORM_VIEW: 'ROLE_FORM_VIEW',
  ROLE_FORM_CREATE: 'ROLE_FORM_CREATE',
  ROLE_FORM_CREATE_APPROVE: 'ROLE_FORM_CREATE_APPROVE',
  ROLE_FORM_UPDATE: 'ROLE_FORM_UPDATE',
  ROLE_FORM_UPDATE_APPROVE: 'ROLE_FORM_UPDATE_APPROVE',
  ROLE_FORM_DELETE: 'ROLE_FORM_DELETE',
  ROLE_FORM_DELETE_APPROVE: 'ROLE_FORM_DELETE_APPROVE',

  ROLE_GOAL_SETTING_VIEW: 'ROLE_GOAL_SETTING_VIEW',
  ROLE_GOAL_SETTING_CREATE: 'ROLE_GOAL_SETTING_CREATE',
  ROLE_GOAL_SETTING_UPDATE: 'ROLE_GOAL_SETTING_UPDATE',
  ROLE_GOAL_SETTING_DELETE: 'ROLE_GOAL_SETTING_DELETE',

  ROLE_PERMISSION_VIEW: 'ROLE_PERMISSION_VIEW',
  ROLE_PERMISSION_CREATE: 'ROLE_PERMISSION_CREATE',
  ROLE_PERMISSION_CREATE_APPROVE: 'ROLE_PERMISSION_CREATE_APPROVE',
  ROLE_PERMISSION_UPDATE: 'ROLE_PERMISSION_UPDATE',
  ROLE_PERMISSION_UPDATE_APPROVE: 'ROLE_PERMISSION_UPDATE_APPROVE',
  ROLE_PERMISSION_DELETE: 'ROLE_PERMISSION_DELETE',
  ROLE_PERMISSION_DELETE_APPROVE: 'ROLE_PERMISSION_DELETE_APPROVE',

  ROLE_LEAD_GROUP_VIEW: 'ROLE_LEAD_GROUP_VIEW',
  ROLE_LEAD_GROUP_CREATE: 'ROLE_LEAD_GROUP_CREATE',
  ROLE_LEAD_GROUP_UPDATE: 'ROLE_LEAD_GROUP_UPDATE',
  ROLE_LEAD_GROUP_DELETE: 'ROLE_LEAD_GROUP_DELETE',

  ROLE_LEAD_SCORE_VIEW: 'ROLE_LEAD_SCORE_VIEW',
  ROLE_LEAD_SCORE_UPDATE: 'ROLE_LEAD_SCORE_UPDATE',
  ROLE_LEAD_SCORE_UPDATE_APPROVE: 'ROLE_LEAD_SCORE_UPDATE_APPROVE',

  ROLE_LEAD_SCORE_CATEGORIES_VIEW: 'ROLE_LEAD_SCORE_CATEGORIES_VIEW',
  ROLE_LEAD_SCORE_CATEGORIES_UPDATE: 'ROLE_LEAD_SCORE_CATEGORIES_UPDATE',
  ROLE_LEAD_SCORE_CATEGORIES_UPDATE_APPROVE: 'ROLE_LEAD_SCORE_CATEGORIES_UPDATE_APPROVE',

  ROLE_LEAVE_APPLICATION_VIEW: 'ROLE_LEAVE_APPLICATION_VIEW',
  ROLE_LEAVE_APPLICATION_CREATE: 'ROLE_LEAVE_APPLICATION_CREATE',
  ROLE_LEAVE_APPLICATION_UPDATE: 'ROLE_LEAVE_APPLICATION_UPDATE',
  ROLE_LEAVE_APPLICATION_DELETE: 'ROLE_LEAVE_APPLICATION_DELETE',
  ROLE_LEAVE_APPLICATION_APPROVE: 'ROLE_LEAVE_APPLICATION_APPROVE',
  ROLE_LEAVE_APPLICATION_REJECT: 'ROLE_LEAVE_APPLICATION_REJECT',
  ROLE_LEAVE_APPLICATION_DELETE_APPROVE: 'ROLE_LEAVE_APPLICATION_DELETE_APPROVE',

  ROLE_LEAVE_SETUP_VIEW: 'ROLE_LEAVE_SETUP_VIEW',
  ROLE_LEAVE_SETUP_CREATE: 'ROLE_LEAVE_SETUP_CREATE',
  ROLE_LEAVE_SETUP_UPDATE: 'ROLE_LEAVE_SETUP_UPDATE',
  ROLE_LEAVE_SETUP_DELETE: 'ROLE_LEAVE_SETUP_DELETE',
  ROLE_LEAVE_SETUP_CREATE_APPROVE: 'ROLE_LEAVE_SETUP_CREATE_APPROVE',
  ROLE_LEAVE_SETUP_UPDATE_APPROVE: 'ROLE_LEAVE_SETUP_UPDATE_APPROVE',
  ROLE_LEAVE_SETUP_DELETE_APPROVE: 'ROLE_LEAVE_SETUP_DELETE_APPROVE',

  ROLE_LOST_REASON_VIEW: 'ROLE_LOST_REASON_VIEW',
  ROLE_LOST_REASON_CREATE: 'ROLE_LOST_REASON_CREATE',
  ROLE_LOST_REASON_CREATE_APPROVE: 'ROLE_LOST_REASON_CREATE_APPROVE',
  ROLE_LOST_REASON_UPDATE: 'ROLE_LOST_REASON_UPDATE',
  ROLE_LOST_REASON_UPDATE_APPROVE: 'ROLE_LOST_REASON_UPDATE_APPROVE',
  ROLE_LOST_REASON_DELETE: 'ROLE_LOST_REASON_DELETE',
  ROLE_LOST_REASON_DELETE_APPROVE: 'ROLE_LOST_REASON_DELETE_APPROVE',

  ROLE_COMPANY_VIEW: 'ROLE_COMPANY_VIEW',
  ROLE_COMPANY_CREATE: 'ROLE_COMPANY_CREATE',
  ROLE_COMPANY_UPDATE: 'ROLE_COMPANY_UPDATE',
  ROLE_COMPANY_BULK_CREATE: 'ROLE_COMPANY_BULK_CREATE',

  ROLE_PERSON_VIEW: 'ROLE_PERSON_VIEW',
  ROLE_PERSON_CREATE: 'ROLE_PERSON_CREATE',
  ROLE_PERSON_UPDATE: 'ROLE_PERSON_UPDATE',
  ROLE_PERSON_BULK_CREATE: "ROLE_PERSON_BULK_CREATE",

  ROLE_PIPELINE_VIEW: 'ROLE_PIPELINE_VIEW',
  ROLE_PIPELINE_CREATE: 'ROLE_PIPELINE_CREATE',
  ROLE_PIPELINE_UPDATE: 'ROLE_PIPELINE_UPDATE',
  ROLE_PIPELINE_DELETE: 'ROLE_PIPELINE_DELETE',

  ROLE_PRODUCT_VIEW: 'ROLE_PRODUCT_VIEW',
  ROLE_PRODUCT_CREATE: 'ROLE_PRODUCT_CREATE',
  ROLE_PRODUCT_CREATE_APPROVE: 'ROLE_PRODUCT_CREATE_APPROVE',
  ROLE_PRODUCT_UPDATE: 'ROLE_PRODUCT_UPDATE',
  ROLE_PRODUCT_UPDATE_APPROVE: 'ROLE_PRODUCT_UPDATE_APPROVE',
  ROLE_PRODUCT_DELETE: 'ROLE_PRODUCT_DELETE',
  ROLE_PRODUCT_DELETE_APPROVE: 'ROLE_PRODUCT_DELETE_APPROVE',

  ROLE_PROPOSAL_VIEW: 'ROLE_PROPOSAL_VIEW',
  ROLE_PROPOSAL_CREATE: 'ROLE_PROPOSAL_CREATE',
  ROLE_PROPOSAL_UPDATE: 'ROLE_PROPOSAL_UPDATE',
  ROLE_PROPOSAL_DELETE: 'ROLE_PROPOSAL_DELETE',

  ROLE_REPORT_VIEW: 'ROLE_REPORT_VIEW',

  ROLE_SETTING_VIEW: 'ROLE_SETTING_VIEW',
  ROLE_SETTING_UPDATE: 'ROLE_SETTING_UPDATE',
  ROLE_SETTING_APPROVE: 'ROLE_SETTING_APPROVE',

  ROLE_TEMPLATE_VIEW: 'ROLE_TEMPLATE_VIEW',
  ROLE_TEMPLATE_CREATE: 'ROLE_TEMPLATE_CREATE',
  ROLE_TEMPLATE_UPDATE: 'ROLE_TEMPLATE_UPDATE',
  ROLE_TEMPLATE_DELETE: 'ROLE_TEMPLATE_DELETE',

  ROLE_USER_ALL_VIEW: 'ROLE_USER_ALL_VIEW',
  ROLE_USER_BLOCK_VIEW: 'ROLE_USER_BLOCK_VIEW',
  ROLE_USER_BLOCK_UPDATE: 'ROLE_USER_BLOCK_VIEW',
  ROLE_USER_VIEW: 'ROLE_USER_VIEW',
  ROLE_USER_CREATE: 'ROLE_USER_CREATE',
  ROLE_USER_UPDATE: 'ROLE_USER_UPDATE',
  ROLE_USER_DELETE: 'ROLE_USER_DELETE',
  ROLE_USER_CREATE_APPROVE: 'ROLE_USER_CREATE_APPROVE',
  ROLE_USER_UPDATE_APPROVE: 'ROLE_USER_UPDATE_APPROVE',
  ROLE_USER_DELETE_APPROVE: 'ROLE_USER_DELETE_APPROVE',
};
