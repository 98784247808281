import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KEYNAME, OCCUR } from '../product-matching.enum';
import { IGeneral } from '../product-matching.interface';
import { ProductMatchingService } from '../product-matching.service';

@Component({
  selector: 'app-product-matching',
  templateUrl: './product-matching.component.html',
  styleUrls: ['./product-matching.component.scss'],
})

export class ProductMatchingComponent implements OnInit {

  step: number = 0;
  cachedStep: number = null;
  successor: KEYNAME[] = [];

  productMatchingForm: FormGroup;
  productMatchingDisplayForm: FormGroup;

  showRecommendationSection: boolean = false;
  recommendedProducts: any[] = [];

  bankingType: any = {
    id: KEYNAME.BANKING_TYPE,
    key: KEYNAME.BANKING_TYPE.toString(),
    question: 'Are you looking for personal or business solution?',
    answer: 'I am looking for',
    options: [
      { id: 0, name: 'Personal Banking', successor: [KEYNAME.PRODUCT_TYPE] },
      { id: 1, name: 'Business Banking', successor: [KEYNAME.PRODUCT_TYPE] }
    ]
  }

  productType: any = {
    id: KEYNAME.PRODUCT_TYPE,
    key: KEYNAME.PRODUCT_TYPE.toString(),
    question: 'How may I help you?',
    answer: 'I am looking for',
    options: [
      { id: 0, name: 'Deposit', successor: [KEYNAME.PURPOSE, KEYNAME.GENDER, KEYNAME.AGE, KEYNAME.NATIONALITY] },
      { id: 1, name: 'Financing', successor: [KEYNAME.AGE, KEYNAME.EMPLOYMENT_STATUS, KEYNAME.TENURE, KEYNAME.NATIONALITY] },
      { id: 2, name: 'Takaful', successor: [KEYNAME.PURPOSE, KEYNAME.AGE, KEYNAME.NATIONALITY] },
      { id: 3, name: 'Will', successor: [] },
      { id: 4, name: 'Program/Skim', successor: [KEYNAME.BUSINESS_SEGMENT, KEYNAME.BUSINESS_NATURE, KEYNAME.FINANCING_TYPE] }
    ]
  }

  purpose: any = {
    id: KEYNAME.PURPOSE,
    key: KEYNAME.PURPOSE.toString(),
    question: 'What is your purpose?',
    answer: 'My purpose is',
    options: [
      { id: 0, name: 'Savings/Current Account', successor: [] },
      { id: 1, name: 'Term Deposit', successor: [] },
      { id: 2, name: 'Takaful', successor: [] }
    ]
  }

  gender: any = {
    id: KEYNAME.GENDER,
    key: KEYNAME.GENDER.toString(),
    question: 'What is your gender?',
    answer: 'I am a',
    options: [
      { id: 0, name: 'Male', successor: [] },
      { id: 1, name: 'Female', successor: [] }
    ]
  }

  age: any = {
    id: KEYNAME.AGE,
    key: KEYNAME.AGE.toString(),
    question: 'How old are you?',
    answer: 'My age is',
    options: [
      { id: 0, name: 'Below 18', successor: [] },
      { id: 1, name: 'Between 18 and 44', successor: [] },
      { id: 2, name: '45 or above', successor: [] }
    ]
  }

  employmentStatus: any = {
    id: KEYNAME.EMPLOYMENT_STATUS,
    key: KEYNAME.EMPLOYMENT_STATUS.toString(),
    question: 'What is your employment status?',
    answer: 'My employment status is',
    options: [
      { id: 0, name: 'Government Employee', successor: [] },
      { id: 1, name: 'Private Employee', successor: [] },
      { id: 2, name: 'Business', successor: [] }
    ]
  }

  businessSegment: any = {
    id: KEYNAME.BUSINESS_SEGMENT,
    key: KEYNAME.BUSINESS_SEGMENT.toString(),
    question: 'What is your business size?',
    answer: 'My business size is',
    options: [
      { id: 0, name: 'Micro', successor: [] },
      { id: 1, name: 'SME', successor: [] },
      { id: 2, name: 'Corporate', successor: [] }
    ]
  }

  businessNature: any = {
    id: KEYNAME.BUSINESS_NATURE,
    key: KEYNAME.BUSINESS_NATURE.toString(),
    question: 'What is your nature of business?',
    answer: 'My business nature is',
    options: [
      { id: 0, name: 'All Industry', successor: [] },
      { id: 1, name: 'Fishery', successor: [] },
      { id: 2, name: 'Livestock', successor: [] },
      { id: 3, name: 'Hire Purchase', successor: [] },
      { id: 4, name: 'Paddy', successor: [] },
      { id: 5, name: 'Rubber', successor: [] },
      { id: 6, name: 'Palm Oil', successor: [] },
      { id: 7, name: 'Crops (other than paddy, rubber, palm oil)', successor: [] },
      { id: 8, name: 'Property', successor: [] },
      { id: 9, name: 'Others', successor: [] }
    ]
  }

  tenure: any = {
    id: KEYNAME.TENURE,
    key: KEYNAME.TENURE.toString(),
    question: 'What is your target tenure?',
    answer: 'I prefer a tenure of',
    options: [
      { id: 0, name: '10 years', successor: [] },
      { id: 1, name: '20 years', successor: [] },
      { id: 2, name: '30 years', successor: [] }
    ]
  }

  nationality: any = {
    id: KEYNAME.NATIONALITY,
    key: KEYNAME.NATIONALITY.toString(),
    question: 'What is your nationality?',
    answer: 'I am a',
    options: [
      { id: 0, name: 'Malaysian', successor: [] },
      { id: 1, name: 'Non-Malaysian', successor: [] }
    ]
  }

  financingType: any = {
    id: KEYNAME.FINANCING_TYPE,
    key: KEYNAME.FINANCING_TYPE.toString(),
    question: 'Which financing type are you looking for?',
    answer: 'I am looking for',
    options: [
      { id: 0, name: 'Term Financing', successor: [] },
      { id: 1, name: 'Revolving/Overdraft Financing', successor: [] },
      { id: 2, name: 'Trade Financing', successor: [] },
      { id: 3, name: 'Qard', successor: [] }
    ]
  }

  constructor(
    private productMatchingService: ProductMatchingService
  ) { }

  ngOnInit() {
    this.productMatchingForm = this.getProductMatchingFormFields();
    this.productMatchingDisplayForm = this.getProductMatchingFormFields();
  }

  getProductMatchingFormFields(): FormGroup {
    return new FormGroup({
      bankingType: new FormControl('', Validators.required),
      productType: new FormControl('', Validators.required),
      purpose: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      age: new FormControl('', Validators.required),
      employmentStatus: new FormControl('', Validators.required),
      businessSegment: new FormControl('', Validators.required),
      businessNature: new FormControl('', Validators.required),
      tenure: new FormControl('', Validators.required),
      nationality: new FormControl('', Validators.required),
      financingType: new FormControl('', Validators.required)
    });
  }

  revertValue(revertStep: number) {
    this.cachedStep = this.step;
    this.step = revertStep;
  }

  checkValue(formControlName: string) {
    if (this.productMatchingForm.controls[formControlName].invalid) {
      return;
    }

    if (this.cachedStep) {
      this.step = this.cachedStep;
      this.cachedStep = null;
    }

    let nextStep: number = this.step;
    let stepFound: boolean = false;
    this.successor.forEach(element => {
      if (!stepFound && this.step < element) {
        nextStep = element;
        stepFound = true;
      }
    });

    if (this.step == nextStep) {
      this.step = Object.keys(KEYNAME).length
    } else {
      this.step = nextStep;
    }

    this.getRecommendedProducts();
  }

  checkValueV2(formControlName: string, array: any) {
    if (this.productMatchingForm.controls[formControlName].invalid) {
      return;
    }

    let id: number = this.productMatchingForm.controls[formControlName].value;

    array.forEach(element => {
      if (element.id == id) {
        this.productMatchingDisplayForm.controls[formControlName].setValue(element.name);
      }
    });

    if (this.cachedStep) {
      this.step = this.cachedStep;
      this.cachedStep = null;
    }

    let nextStep: number = this.step;
    let stepFound: boolean = false;
    this.successor.forEach(element => {
      if (!stepFound && this.step < element) {
        nextStep = element;
        stepFound = true;
      }
    });

    if (this.step == nextStep) {
      this.step = Object.keys(KEYNAME).length
    } else {
      this.step = nextStep;
    }

    this.getRecommendedProducts();
  }

  resetFormPartially(formControlName: string, array: any) {
    let id: number = this.productMatchingForm.controls[formControlName].value;

    array.forEach(element => {
      if (element.id == id) {
        this.successor = element.successor;
        this.cachedStep = null;
      }
    });

    this.productMatchingForm.patchValue({
      purpose: '',
      gender: '',
      age: '',
      employmentStatus: '',
      businessSegment: '',
      businessNature: '',
      tenure: '',
      nationality: '',
      financingType: ''
    });

    this.productMatchingDisplayForm.patchValue({
      purpose: '',
      gender: '',
      age: '',
      employmentStatus: '',
      businessSegment: '',
      businessNature: '',
      tenure: '',
      nationality: '',
      financingType: ''
    });
  }

  contactUs() {

  }

  discoverMore() {

  }

  applyNow() {

  }

  research() {
    this.productMatchingForm.reset();
    this.step = 0;
    this.showRecommendationSection = false;
    this.recommendedProducts = [];
  }

  private getRecommendedProducts() {

    if (this.step != Object.keys(KEYNAME).length) {
      return;
    }

    this.showRecommendationSection = true;
    this.recommendedProducts = [];

    let body: any[] = [];

    Object.keys(this.productMatchingForm.controls).forEach(key => {

      let value: any = this.productMatchingForm.controls[key].value;
      let occurance: string = OCCUR[OCCUR.SHOULD];
      if (value) {
        occurance = OCCUR[OCCUR.MUST];
      }

      let pile: any = {
        keyName: key,
        keyValue: value,
        occur: occurance
      };

      body.push(pile);
    });

    for (let a = 0; a <= 2; a++) {

      let fakeData: any = {
        name: 'Lorem ipsum ' + (a + 1),
        subtitle: 'Lorem ipsum',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
      }

      this.recommendedProducts.push(fakeData);

    }

    // this.productMatchingService.getRecommendedProducts(this.productMatchingForm.value).subscribe({
    //   next: (n) => {
    //     console.log(n);
    //   },
    //   error: (e) => {
    //   },
    //   complete: () => {
    //   }
    // });
  }

}
