import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncryptDecryptService } from 'src/app/lms/common/encrypt-decrypt.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class EncryptInterceptor implements HttpInterceptor {
    constructor(
        private encryptDecryptService: EncryptDecryptService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // console.log("Params",request.urlWithParams);

        // var encodedUrl = ESAPI.encoder().encodeForURL(request.urlWithParams, true);

        // console.log("Params Encoded",encodedUrl);

        // console.log();

        //Encrypt API
        if(request.body 
            && !request.url.startsWith(environment.baseUrl+"/file-manager/replace") 
            && !request.url.startsWith(environment.baseUrl+"/file-manager/upload")
            && !request.url.startsWith(environment.baseUrl+"/company/add-bulk")
            && !request.url.startsWith(environment.baseUrl+"/person/add-bulk")){
            var ciphertext = this.encryptDecryptService.encrypt(JSON.stringify(request.body));
            request = request.clone({
                body: {
                    data: ciphertext
                }
            });
        }

        return next.handle(request);

    }
    
    // encrypt(word: any) {
    //     const keyBase64 = "o9szYIOq1rRMiouNhNvaq96lqUvCekxR";
    //     var key = CryptoJS.enc.Base64.parse(keyBase64);
    //     var srcs = CryptoJS.enc.Utf8.parse(word);
    //     var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    //     return encrypted.toString();
    // }
    
    //   decrypt(word: any) {
    //     const keyBase64 = "o9szYIOq1rRMiouNhNvaq96lqUvCekxR";
    //     var key = CryptoJS.enc.Base64.parse(keyBase64);
    //     var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    //     return CryptoJS.enc.Utf8.stringify(decrypt);
    //   }
    
}