<div class="container">
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="!isUnavailable; else unavailable">
      <div class="card my-5 border border-light">
        <div class="card-body">
          <div class="justify-content-center d-flex">
            <img src="assets/images/Bank-Islam-LOGO_small.png" alt="bimb-icon" height="80" />
          </div>

          <h1 class="text-center mt-2 mb-4">
            <b>
              {{ marketingDetails?.name }} {{ marketingDetails?.productType ? "- " + marketingDetails?.productType.name
              :
              "" }}
            </b>
          </h1>

          <div class="mx-5">
            <form [formGroup]="form">
              <div class="form-group">
                <div class="row mb-3" *ngIf="!lockLeadType">
                  <label class="col-md-2 col-form-label">Lead Type</label>
                  <div class="col-md-10">
                    <mat-select class="form-control" formControlName="leadType" placeholder="Please select Lead Type"
                      (selectionChange)="setFormFields()"
                      [ngClass]="{ 'is-invalid': submitClicked && formControls.leadType.errors }">
                      <mat-option *ngFor="let data of leadTypeList" value="{{ data }}">{{ data | titlecase
                        }}</mat-option>
                    </mat-select>
                    <div class="invalid-feedback">Lead Type is required</div>
                  </div>
                </div>

                <ng-container *ngFor="let field of formFieldCustomList">
                  <div class="row mb-3" *ngIf="field.formFieldDefault.variableSubType === 'product_list'">
                    <label class="col-md-2 col-form-label">Product Type</label>
                    <div class="col-md-10">
                      <mat-select class="form-control" formControlName="productTypeId"
                        placeholder="Please select Product Type" (selectionChange)="getProductByType()"
                        [ngClass]="{ 'is-invalid': submitClicked && formControls.productTypeId.errors }">
                        <mat-option *ngFor="let data of productTypeList" value="{{ data.id }}">{{ data.name
                          }}</mat-option>
                      </mat-select>
                      <div class="invalid-feedback">Product Type is required</div>
                    </div>
                  </div>

                  <div *ngIf="field.formFieldDefault.variableSubType === 'branch_list'">
                    <div class="row mb-3">
                      <label class="col-md-2 col-form-label">Preferred State</label>
                      <div class="col-md-10">
                        <mat-select class="form-control" formControlName="stateId" placeholder="Please select a state"
                          (selectionChange)="filterBranches($event.value)"
                          [ngClass]="{ 'is-invalid': submitClicked && formControls['stateId'].errors }">

                          <mat-option *ngFor="let data of stateList" value="{{ data.id }}">
                            {{data.name}}
                          </mat-option>
                        </mat-select>

                        <div class="row" *ngIf="!field.required">
                          <div class="col-12 col text-end">
                            <small class="text-muted">(Optional)</small>
                          </div>
                        </div>

                        <div class="invalid-feedback">
                          <span *ngIf="commonService.validationChecking('stateId', 'required', form, submitClicked)">
                            State is required
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <label class="col-md-2 col-form-label">Preferred Branch</label>
                      <div class="col-md-10">
                        <mat-select class="form-control" formControlName="branchId"
                          placeholder="Please select {{ field.formFieldDefault.name }}"
                          [ngClass]="{ 'is-invalid': submitClicked && formControls[field.formFieldDefault.variableName].errors }">

                          <mat-option *ngFor="let data of branchList" value="{{ data.id }}">
                            {{data.name}}
                          </mat-option>
                        </mat-select>

                        <div class="row" *ngIf="!field.required">
                          <div class="col-12 col text-end">
                            <small class="text-muted">(Optional)</small>
                          </div>
                        </div>
                        <div class="invalid-feedback">
                          <span
                            *ngIf="commonService.validationChecking(field.formFieldDefault.variableName, 'required', form, submitClicked)">
                            {{ field.formFieldDefault.name }} is required
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3" *ngIf="showField(field.formFieldDefault.variableName)">
                    <label class="col-md-2 col-form-label">{{ field.formFieldDefault.name | titlecase }}</label>
                    <div class="col-md-10">
                      <input *ngIf="field.formFieldDefault.variableType === 'string'" type="text" maxlength="100"
                        class="form-control" formControlName="{{ field.formFieldDefault.variableName }}"
                        placeholder="Please enter {{ field.formFieldDefault.name }}" [ngClass]="{
                          'is-invalid': submitClicked && formControls[field.formFieldDefault.variableName].errors
                        }" />
                      <mat-select
                        *ngIf="field.formFieldDefault.variableType === 'select' && (field.formFieldDefault.variableSubType != 'branch_list' && field.formFieldDefault.variableSubType != 'state_list')"
                        class="form-control" formControlName="{{ field.formFieldDefault.variableName }}"
                        placeholder="Please select {{ field.formFieldDefault.name }}"
                        (selectionChange)="checkValidation($event.value)" [ngClass]="{
                          'is-invalid': submitClicked && formControls[field.formFieldDefault.variableName].errors
                        }">
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'lead_group_company_list'">
                          <mat-option *ngFor="let data of leadGroupCompanyList" value="{{ data.id }}">{{ data.name
                            }}</mat-option>
                        </ng-container>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'lead_group_individual_list'">
                          <mat-option *ngFor="let data of leadGroupIndividualList" value="{{ data.id }}">{{ data.name
                            }}</mat-option>
                        </ng-container>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'company_type_list'">
                          <mat-option *ngFor="let data of companyTypeList" value="{{ data }}">
                            {{ "ENUMS.COMPANY_TYPE." + data | translate }}
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'sector_list'">
                          <mat-option *ngFor="let data of sectorList" value="{{ data }}">
                            {{ "ENUMS.SECTOR." + data | translate }}
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'salutation_list'">
                          <mat-option *ngFor="let data of salutationList" value="{{ data }}">
                            {{ "ENUMS.SALUTATION." + data | translate }}
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'id_type_list'">
                          <mat-option *ngFor="let data of idTypeList" value="{{ data }}">
                            {{ "ENUMS.IC_TYPE." + data | translate }}
                          </mat-option>
                        </ng-container>
                      </mat-select>

                      <mat-select *ngIf="field.formFieldDefault.variableType === 'multi_select'" class="form-control"
                        formControlName="{{ field.formFieldDefault.variableName }}" [ngClass]="{
                          'is-invalid': submitClicked && formControls[field.formFieldDefault.variableName].errors
                        }" placeholder="Please select {{ field.formFieldDefault.name }}" multiple>
                        <ng-container *ngIf="field.formFieldDefault.variableSubType === 'product_list'">
                          <mat-option *ngFor="let data of productList" value="{{ data.id }}">
                            {{ data.name }}
                          </mat-option>
                        </ng-container>
                      </mat-select>

                      <!-- <ng-container *ngIf="field.formFieldDefault.variableSubType === 'branch_list'">
                        <nz-tree-select
                          class="form-control"
                          [nzShowSearch]="BranchTreeSelectConfig.showSearch"
                          [nzHideUnMatched]="BranchTreeSelectConfig.hideUnmatched"
                          [nzPlaceHolder]="BranchTreeSelectConfig.placeHolder"
                          [nzNotFoundContent]="BranchTreeSelectConfig.notFoundContent"
                          [nzDropdownStyle]="BranchTreeSelectConfig.dropdownStyle"
                          [nzDropdownMatchSelectWidth]="BranchTreeSelectConfig.dropdownMatchSelectWidth"
                          [nzDefaultExpandAll]="BranchTreeSelectConfig.defaultExpandAll"
                          [nzNodes]="branchTree"
                          formControlName="branchId"
                          [ngClass]="{ 'is-invalid': submitClicked && formControls[field.formFieldDefault.variableName].errors }"
                        ></nz-tree-select>
                      </ng-container> -->

                      <div class="row" *ngIf="!field.required">
                        <div class="col-12 col text-end">
                          <small class="text-muted">(Optional)</small>
                        </div>
                      </div>
                      <p class="m-0 text-muted" *ngIf="field.formFieldDefault.example">{{ field.formFieldDefault.example
                        }}</p>
                      <div class="invalid-feedback">
                        <span
                          *ngIf="commonService.validationChecking(field.formFieldDefault.variableName, 'required', form, submitClicked)">
                          {{ field.formFieldDefault.name }} is required
                        </span>
                        <span
                          *ngIf="commonService.validationChecking(field.formFieldDefault.variableName, 'email', form, submitClicked)">
                          Invalid Email Format
                        </span>
                        <span
                          *ngIf="commonService.validationChecking(field.formFieldDefault.variableName, 'pattern', form, submitClicked)">
                          Invalid Format
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </form>

            <div class="mb-3 row">
              <div class="col-md-12">
                <div class="text-center fw-bold bg-light p-4" style="font-size: 12px">
                  <div [innerHTML]="pdpaConfig.acknowledge1"></div>
                  <!-- <br /><br />
                  {{ pdpaConfig.acknowledge2 }} -->
                </div>

                <div class="mt-2">
                  <input type="checkbox" class="form-checkbox" [(ngModel)]="pdpaAgree" />
                  <span class="fw-bold" style="font-size: 12px">&nbsp;&nbsp;{{ pdpaConfig.agree }}</span>
                </div>
              </div>
            </div>
          </div>


          <div class="row my-4 d-flex justify-content-center">
            <div class="col-md-2">
              <button class="btn btn-primary w-100" [disabled]="!pdpaAgree"
                (click)="submit(successModal, failModal)">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <div class="card my-5 py-5 border border-light">
    <div class="card-body">
      <h4 class="text-center">
        Loading...<br />
        <span class="spinner-border spinner-border-lg text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </span>
      </h4>
    </div>
  </div>
</ng-template>

<ng-template #unavailable>
  <div class="card my-5 py-5 border border-light">
    <div class="card-body">
      <h4 class="text-center">This E-Form is currently unavailable</h4>
    </div>
  </div>
</ng-template>

<ng-template #successModal let-modal>
  <div class="modal-body p-5 text-center">
    <p class="text-success m-0" style="font-size: 60px">
      <i class="bx bxs-check-circle"></i>
    </p>
    <h1>Success</h1>
    <p>Your response has been submitted</p>
    <button class="btn btn-success" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #failModal let-modal>
  <div class="modal-body p-5 text-center">
    <p class="text-danger m-0" style="font-size: 60px">
      <i class="bx bxs-x-circle"></i>
    </p>
    <h1>Failed</h1>
    <p>{{ failModalMessage }}</p>
    <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>