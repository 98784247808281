import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { EncryptDecryptService } from 'src/app/lms/common/encrypt-decrypt.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DecryptInterceptor implements HttpInterceptor {
  constructor(private store: Store, private encryptDecryptService: EncryptDecryptService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body?.encodedString) {
          var decryptedText = this.encryptDecryptService.decrypt(event.body.encodedString);
          event = event.clone({ body: JSON.parse(decryptedText) });

          if (!environment.production) {
            console.info(req.method, req.url);
            console.info(JSON.parse(decryptedText));
          }
        }
        return event;
      })
    );
  }
}
