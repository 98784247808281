import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormSubmissionComponent } from './form-submission.component';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';

@NgModule({
  declarations: [FormSubmissionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    NzTreeSelectModule,
  ],
  bootstrap: [FormSubmissionComponent],
})
export class FormSubmissionModule {}
