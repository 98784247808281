import { PERMISSIONS_DATA } from 'src/app/lms/data/permissions.data';
import { MenuItem } from './menu.model';

export const LMS_MENU: MenuItem[] = [
    {
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/lms/dashboard',
        roleName: [PERMISSIONS_DATA.ROLE_DASHBOARD_VIEW]
    },
    {
        label: 'Products & Distribution',
        icon: 'bx-package',
        roleName: [PERMISSIONS_DATA.ROLE_PRODUCT_VIEW, PERMISSIONS_DATA.ROLE_DISTRIBUTION_GROUP_VIEW, PERMISSIONS_DATA.ROLE_CRITERIA_VIEW],
        subItems: [
            {
                label: 'LMS.SIDEBAR.PRODUCTS',
                parentId: 1,
                link: '/lms/products/list',
                roleName: [PERMISSIONS_DATA.ROLE_PRODUCT_VIEW]
            },
            {
                label: 'Distribution Group',
                parentId: 1,
                link: '/lms/products/distribution-group',
                roleName: [PERMISSIONS_DATA.ROLE_DISTRIBUTION_GROUP_VIEW]
            },
            {
                label: 'Criteria Setup',
                parentId: 1,
                link: '/lms/products/criteria-setup',
                roleName: [PERMISSIONS_DATA.ROLE_CRITERIA_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.LEADS',
        icon: 'bx-group',
        roleName: [
            PERMISSIONS_DATA.ROLE_PERSON_VIEW, 
            PERMISSIONS_DATA.ROLE_COMPANY_VIEW, 
            PERMISSIONS_DATA.ROLE_GOAL_SETTING_VIEW
        ],
        subItems: [
            {
                label: 'LMS.SIDEBAR.LEADSSUB.PEOPLE',
                parentId: 2,
                link: '/lms/leads/people',
                roleName: [PERMISSIONS_DATA.ROLE_PERSON_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.ORGANIZATIONS',
                parentId: 2,
                link: '/lms/leads/company',
                roleName: [PERMISSIONS_DATA.ROLE_COMPANY_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.GOALSETTINGS',
                parentId: 20,
                link: '/lms/leads/goal-setting',
                roleName: [PERMISSIONS_DATA.ROLE_GOAL_SETTING_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.LEADCONFIGURATIONS',
        icon: 'bx-user-check',
        roleName: [
            PERMISSIONS_DATA.ROLE_LEAD_GROUP_VIEW, 
            PERMISSIONS_DATA.ROLE_LEAD_SCORE_VIEW,
            PERMISSIONS_DATA.ROLE_LEAD_SCORE_CATEGORIES_VIEW,
            PERMISSIONS_DATA.ROLE_FORM_VIEW, 
            PERMISSIONS_DATA.ROLE_CHANNEL_VIEW,
            PERMISSIONS_DATA.ROLE_CAMPAIGN_VIEW
        ],
        subItems: [
            {
                label: 'LMS.SIDEBAR.LEADSSUB.LEADGROUPS',
                parentId: 2,
                link: '/lms/leads/lead-group',
                roleName: [PERMISSIONS_DATA.ROLE_LEAD_GROUP_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.SCORESETTINGS',
                parentId: 20,
                link: '/lms/leads/lead-score',
                roleName: [PERMISSIONS_DATA.ROLE_LEAD_SCORE_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.LEADSCORECATEGORY',
                parentId: 20,
                link: '/lms/leads/lead-score-categories',
                roleName: [PERMISSIONS_DATA.ROLE_LEAD_SCORE_CATEGORIES_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.EFORM',
                parentId: 20,
                link: '/lms/leads/form',
                roleName: [PERMISSIONS_DATA.ROLE_FORM_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.CHANNELS',
                parentId: 20,
                link: '/lms/leads/channel',
                roleName: [PERMISSIONS_DATA.ROLE_CHANNEL_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEADSSUB.CAMPAIGN',
                parentId: 20,
                link: '/lms/leads/campaign',
                roleName: [PERMISSIONS_DATA.ROLE_CAMPAIGN_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.DEALS',
        icon: 'bx-task',
        roleName: [PERMISSIONS_DATA.ROLE_PIPELINE_VIEW, PERMISSIONS_DATA.ROLE_DEAL_VIEW, PERMISSIONS_DATA.ROLE_LOST_REASON_VIEW],
        subItems: [
            {
                label: 'LMS.SIDEBAR.DEALSSUB.PIPELINEVIEW',
                parentId: 6,
                link: '/lms/deals/pipeline-view',
                roleName: [PERMISSIONS_DATA.ROLE_PIPELINE_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.DEALSSUB.ALLDEALS',
                parentId: 6,
                link: '/lms/deals/all-deals',
                roleName: [PERMISSIONS_DATA.ROLE_DEAL_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.DEALSSUB.PIPELINES',
                parentId: 6,
                link: '/lms/deals/pipelines',
                roleName: [PERMISSIONS_DATA.ROLE_PIPELINE_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.DEALSSUB.LOSTREASONS',
                parentId: 6,
                link: '/lms/deals/lost-reasons',
                roleName: [PERMISSIONS_DATA.ROLE_LOST_REASON_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.PROPOSALS',
        icon: 'mdi mdi-hexagon-outline',
        roleName: [PERMISSIONS_DATA.ROLE_PROPOSAL_VIEW, PERMISSIONS_DATA.ROLE_TEMPLATE_VIEW],
        subItems: [
            {
                label: 'LMS.SIDEBAR.PROPOSALSSUB.LIST',
                parentId: 11,
                link: '/lms/proposals/proposal-list',
                roleName: [PERMISSIONS_DATA.ROLE_PROPOSAL_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.PROPOSALSSUB.TEMPLATES',
                parentId: 11,
                link: '/lms/proposals/templates',
                roleName: [PERMISSIONS_DATA.ROLE_TEMPLATE_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.ACTIVITIES',
        icon: 'dripicons-graph-line',
        roleName: [PERMISSIONS_DATA.ROLE_ACTIVITY_VIEW, PERMISSIONS_DATA.ROLE_ACTIVITY_APPOINTMENT_SETUP_VIEW],
        subItems: [
            {
                label: 'LMS.SIDEBAR.ACTIVITIESSUB.CALENDAR',
                parentId: 13,
                link: '/lms/activities/calendar-view',
                roleName: [PERMISSIONS_DATA.ROLE_ACTIVITY_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.ACTIVITIESSUB.LIST',
                parentId: 13,
                link: '/lms/activities/activity-list',
                roleName: [PERMISSIONS_DATA.ROLE_ACTIVITY_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.ACTIVITIESSUB.APPOINTMENTSETUP',
                parentId: 13,
                link: '/lms/activities/appointment',
                roleName: [PERMISSIONS_DATA.ROLE_ACTIVITY_APPOINTMENT_SETUP_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.REPORTS',
        icon: 'bx bx-bar-chart',
        roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW],
        subItems: [
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.PRODUCT',
                parentId: 16,
                link: '/lms/reports/product',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.LEADS',
                parentId: 16,
                link: '/lms/reports/leads',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.DEAL',
                parentId: 16,
                link: '/lms/reports/deal',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.LOSTREASONS',
                parentId: 16,
                link: '/lms/reports/lost-reasons',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.PROPOSAL',
                parentId: 16,
                link: '/lms/reports/proposal',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.ACTIVITY',
                parentId: 16,
                link: '/lms/reports/activity',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.PIPELINE',
                parentId: 16,
                link: '/lms/reports/pipeline',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.USERMANAGEMENT',
                parentId: 16,
                link: '/lms/reports/user-management',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.REPORTSSUB.AUDIT',
                parentId: 16,
                link: '/lms/reports/audit',
                roleName: [PERMISSIONS_DATA.ROLE_REPORT_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.USERSMANAGEMENT',
        icon: 'bx bx-user-check',
        roleName: [
            PERMISSIONS_DATA.ROLE_USER_ALL_VIEW,
            PERMISSIONS_DATA.ROLE_USER_VIEW,
            PERMISSIONS_DATA.ROLE_CHANNEL_SETUP_VIEW,
            PERMISSIONS_DATA.ROLE_USER_BLOCK_VIEW,
            PERMISSIONS_DATA.ROLE_BRANCH_VIEW,
            PERMISSIONS_DATA.ROLE_PERMISSION_VIEW
        ],
        subItems: [
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.ALLUSERS',
                parentId: 20,
                link: '/lms/users-management/all-users',
                roleName: [PERMISSIONS_DATA.ROLE_USER_ALL_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.CLIENTS',
                parentId: 20,
                link: '/lms/users-management/client',
                roleName: [PERMISSIONS_DATA.ROLE_CHANNEL_SETUP_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.USERS',
                parentId: 20,
                link: '/lms/users-management/users',
                roleName: [PERMISSIONS_DATA.ROLE_USER_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.BLOCK',
                parentId: 20,
                link: '/lms/users-management/blocked',
                roleName: [PERMISSIONS_DATA.ROLE_USER_BLOCK_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.BRANCHES',
                parentId: 20,
                link: '/lms/users-management/branches',
                roleName: [PERMISSIONS_DATA.ROLE_BRANCH_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.USERSMANAGEMENTSUB.PERMISSIONS',
                parentId: 20,
                link: '/lms/users-management/permissions',
                roleName: [PERMISSIONS_DATA.ROLE_PERMISSION_VIEW]
            },

        ]
    },
    {
        label: 'LMS.SIDEBAR.LEAVEMANAGEMENT',
        icon: 'bx bx-calendar-event',
        roleName: [
            PERMISSIONS_DATA.ROLE_LEAVE_SETUP_VIEW,
            PERMISSIONS_DATA.ROLE_LEAVE_APPLICATION_VIEW
        ],
        subItems: [
            {
                label: 'LMS.SIDEBAR.LEAVEMANAGEMENTSUB.SETUP',
                parentId: 20,
                link: '/lms/leave-management/setup',
                roleName: [PERMISSIONS_DATA.ROLE_LEAVE_SETUP_VIEW]
            },
            {
                label: 'LMS.SIDEBAR.LEAVEMANAGEMENTSUB.APPLICATION',
                parentId: 20,
                link: '/lms/leave-management/application',
                roleName: [PERMISSIONS_DATA.ROLE_LEAVE_APPLICATION_VIEW]
            }
        ]
    },
    {
        label: 'LMS.SIDEBAR.FILEMANAGER',
        icon: 'bx bx-file',
        link: '/lms/filemanager',
        roleName: [PERMISSIONS_DATA.ROLE_FILE_VIEW]
    },
    {
        label: 'LMS.SIDEBAR.SETTINGS',
        icon: 'bx bx-cog',
        link: '/lms/settings',
        roleName: [PERMISSIONS_DATA.ROLE_SETTING_VIEW]
    },
    // {
    //     label: 'LMS.SIDEBAR.JOBSCHEDULER',
    //     icon: 'bx bx-briefcase-alt',
    //     link: '/lms/jobscheduler',
    //     roleName: [PERMISSIONS_DATA.ROLE_AUDIT_VIEW]
    // },
    {
        label: 'LMS.SIDEBAR.AUDIT',
        icon: 'bx bx-list-check',
        link: '/lms/audit',
        roleName: [PERMISSIONS_DATA.ROLE_AUDIT_VIEW]
    },
];
