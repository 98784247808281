import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/lms/common/common.service';
import { EncryptDecryptService } from 'src/app/lms/common/encrypt-decrypt.service';
import { NotificationService } from 'src/app/lms/notification/notification/notification.service';
import { UserService } from 'src/app/lms/users-management/user.service';
import { LanguageService } from '../../core/services/language.service';
import { LayoutComponent } from '../layout.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  notificationCount: number;
  public notifications;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  page: number = 0;
  pageSize: number = 5;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private notificationService: NotificationService,
    private usersService: UserService,
    private encryptDecryptService: EncryptDecryptService,
    private layoutService: LayoutComponent,
    public commonService: CommonService
  ) {}

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.getUserDetails();
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.getNotificationsBellPage();
    this.getNotificationCount();
    this.initListener();

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // Using the logout function from Layout Component to stop the interval and countdown to continue count
    this.layoutService.logout();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  goToEditUser() {
    this.router.navigate(['/lms/users-management/user/profile']);
  }

  getUser() {
    return UserService.userDetails;
  }

  getUserDetails() {
    this.usersService
      .getUserDetails()
      .toPromise()
      .then((data) => {
        UserService.userDetails = data;
        if (UserService.userDetails?.storage) {
          UserService.userDetails.storage.data = 'data:image/jpeg;base64,' + UserService.userDetails.storage.data;
        }
      });
  }
  routeToAllNotificationPage() {
    this.router.navigate(['/lms/notification']);
  }

  getNotificationsBellPage() {
    let pageableRequest: any = {
      page: this.page,
      size: this.pageSize,
    };

    this.notificationService.getNotificationsBellPage(pageableRequest).subscribe({
      next: (n) => {
        this.notifications = n;
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  getNotificationCount() {
    this.notificationService.getNotificationCount().subscribe({
      next: (n) => {
        this.notificationCount = n;
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  public initListener() {
    NotificationService.LastMessage.subscribe((value) => {
      this.getNotificationCount();
      this.getNotificationsBellPage();
    });
  }

  readNotificationsById(obj: any) {
    this.notificationService.readNotificationsById(obj.id).subscribe({
      next: (n) => {
        let id = this.encryptDecryptService.encryptURL(obj?.instanceId);

        if (obj.eventResponse.name.includes('Deleted')) {
          console.log('Delete');
          if (obj.eventResponse.module == 'deals') {
            this.router.navigateByUrl('/lms/deals/all-deals');
          } else if (obj.eventResponse.module == 'proposals') {
            this.router.navigateByUrl('/lms/proposals/proposal-list');
          }
        } else {
          console.log(obj.eventResponse.module);
          if (obj.eventResponse.module == 'people') {
            this.router.navigateByUrl('/lms/leads/people/' + id);
          }
          if (obj.eventResponse.module == 'deals') {
            this.router.navigateByUrl('/lms/leads/deal/' + id);
          } else if (obj.eventResponse.module == 'company') {
            this.router.navigateByUrl('/lms/leads/company/' + id);
          } else if (obj.eventResponse.module == 'users') {
            this.router.navigateByUrl('/lms/users-management/users/' + id);
          } else if (obj.eventResponse.module == 'activities') {
            this.router.navigateByUrl('/lms/activities/calendar-view/' + id);
          } else if (obj.eventResponse.module == 'proposals') {
            this.router.navigateByUrl('/lms/proposals/proposal-list/' + id);
          }
          this.getNotificationsBellPage();
        }
      },
      error: (e) => {},
      complete: () => {},
    });
  }
}
