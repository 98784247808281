import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/lms/common/common.service';
import { PeopleService } from 'src/app/lms/leads/people.service';
import { ProductsService } from 'src/app/lms/products/products.service';

@Component({
  selector: 'app-lead-microsite',
  templateUrl: './lead-microsite.component.html',
  styleUrls: ['./lead-microsite.component.scss'],
})
export class LeadMicrositeComponent implements OnInit {


  countryName = new FormControl();
  countries;
  filteredCountries;

  submitClicked: boolean = false;
  peopleForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.minLength(10), Validators.maxLength(11)]),
    email: new FormControl('', Validators.email),
    city: new FormControl('', Validators.required),
    product: new FormControl('', Validators.required),
  });

  productsList: any;

  constructor(
    private peopleService: PeopleService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private matSnackBar: MatSnackBar,
    private productsService: ProductsService
  ) { }

  async ngOnInit() {

    this.getProductList();

    this.countries = await this.commonService.getCountryList().toPromise();

    this.filteredCountries = this.countryName.valueChanges.pipe(
      startWith(null),
      map((value) => value ? this._filterCountries(value) : this.countries.slice()));
  }

  getProductList() {

    this.productsService.getActiveProducts().subscribe(
      data => {
        this.productsList = data;
      }, error => {
      }
    )
  }

  selectProduct(productName: any) {
    this.peopleForm.controls.product.setValue(productName);
  }

  openSuccessModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  private _filterCountries(value: string) {
    const filterValue = value.toLowerCase();

    return this.countries.filter(country => country.name.toLowerCase().includes(filterValue));
  }

  addLead(modal: any) {
    this.submitClicked = true;

    if (this.peopleForm.invalid || this.invalidPhoneOrEmail()) {
      return;
    }

    this.peopleService.addPeopleFromMicrosite(this.peopleForm.value).subscribe({
      next: (n) => {
        this.openSuccessModal(modal);
        this.peopleForm.reset();
        this.submitClicked = false;
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  blockCopyPaste() {
    document.querySelectorAll('.block-copy-paste').forEach(item => {
      item.addEventListener("paste", function (evt) {
        if (evt.type.match(/[^\d]/)) {
          event.preventDefault();
        }
      })
    });
  }

  invalidPhoneOrEmail(): boolean {
    return (this.peopleForm.value.phone === '' && this.peopleForm.value.email === '');
  }

  get peopleFormControls() {
    return this.peopleForm.controls;
  }

}
