import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';
import { IMarketingCreateResponse } from './interfaces/form.interface';

@Injectable({
  providedIn: 'root',
})
export class MarketingService {
  private moduleBaseUrl: string = environment.baseUrl + '/marketing';

  constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  getMarketingByPage(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/page`, body);
  }

  getAsList(marketingType: string | null): Observable<any> {
    let url: string = '';
    if (marketingType) {
      url = this.moduleBaseUrl + '/list?encrypted=' + this.encryptDecryptService.encryptParam(marketingType);
    } else {
      url = this.moduleBaseUrl + '/list';
    }
    return this.httpClient.get<any>(url);
  }

  getChannelByCode(code: string): Observable<any> {
    const url: string = this.moduleBaseUrl + '/code?encrypted=' + this.encryptDecryptService.encryptParam(code);
    return this.httpClient.get<any>(url);
  }

  createIndividual(body: any): Observable<IMarketingCreateResponse> {
    return this.httpClient.post<IMarketingCreateResponse>(`${this.moduleBaseUrl}/individual`, body);
  }

  createCompany(body: any): Observable<IMarketingCreateResponse> {
    return this.httpClient.post<IMarketingCreateResponse>(`${this.moduleBaseUrl}/company`, body);
  }

  createChannel(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/channel/create`, body);
  }

  updateChannel(id: string, body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/update?encrypted=${this.encryptDecryptService.encryptParam(id)}`, body);
  }

  deleteChannel(id: number): Observable<any> {
    return this.httpClient.delete(`${this.moduleBaseUrl}/channel/delete?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  getChannelByPage(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/channel/page`, body);
  }

  getChannelById(id: string): Observable<any> {
    return this.httpClient.get(`${this.moduleBaseUrl}/channel?encrypted=${this.encryptDecryptService.encryptParam(id)}`);
  }

  getChannelProductByPage(id: string, body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/channel/product/page?encrypted=${this.encryptDecryptService.encryptParam(id)}`, body);
  }

  getChannelProductByList(id: string): Observable<any> {
    return this.httpClient.get(`${this.moduleBaseUrl}/channel/product/list?encrypted=${this.encryptDecryptService.encryptParam(id)}`);
  }

  getTmpChannelProductByPage(id: string, body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/channel/tmp-product/page?encrypted=${this.encryptDecryptService.encryptParam(id)}`, body);
  }

  approveCreateChannel(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/approve/create?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  approveUpdateChannel(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/approve/update?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  approveDeleteChannel(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/approve/delete?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  rejectCreateChannel(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/reject/create?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }

  rejectUpdateChannel(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/reject/update?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }

  rejectDeleteChannel(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/channel/reject/delete?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }

  deleteChannelProduct(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/channel/product/delete`, body);
  }

  //CAMPAIGN
  getCampaignByPage(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/campaign/page`, body);
  }

  createCampaign(body: any): Observable<any> {
    return this.httpClient.post(`${this.moduleBaseUrl}/campaign/create`, body);
  }

  updateCampaign(id: string, body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/update?encrypted=${this.encryptDecryptService.encryptParam(id)}`, body);
  }

  deleteCampaign(id: number): Observable<any> {
    return this.httpClient.delete(`${this.moduleBaseUrl}/campaign/delete?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  getCampaignById(id: string): Observable<any> {
    return this.httpClient.get(`${this.moduleBaseUrl}/campaign?encrypted=${this.encryptDecryptService.encryptParam(id)}`);
  }

  approveCreateCampaign(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/approve/create?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  approveUpdateCampaign(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/approve/update?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  approveDeleteCampaign(id: string): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/approve/delete?encrypted=${this.encryptDecryptService.encryptParam(id)}`, {});
  }

  rejectCreateCampaign(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/reject/create?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }

  rejectUpdateCampaign(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/reject/update?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }

  rejectDeleteCampaign(body: any): Observable<any> {
    return this.httpClient.put(`${this.moduleBaseUrl}/campaign/reject/delete?encrypted=${this.encryptDecryptService.encryptParam(body.id)}`, body);
  }
}
