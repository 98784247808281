import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  public static userDetails: any;

  getUsersAsList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/list');
  }

  getUserDropdownList(pageableRequest: any = {}): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/page/basic', pageableRequest);
  }

  getUserList(pageableRequest: any = {}): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/viewable-list', { params: pageableRequest });
  }

  getUsersAsListByBranchId(branchId: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/list/branch?encrypted=' + this.encryptDecryptService.encryptParam(branchId));
  }

  getClientList() {
    return this.httpClient.get(environment.baseUrl + '/users/list/client');
  }

  getUsersPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/all-users', pageableRequest);
  }

  getBranchUsersPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/page', pageableRequest);
  }

  getUserDetailsUrl: string = '/users/details';
  getUserDetails(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}${this.getUserDetailsUrl}`);
  }

  getUserById(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/get/details?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  uploadProfilePic(file: any) {
    return this.httpClient.put(environment.baseUrl + '/users/profile/picture', file);
  }

  updateUserProfile(data: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/profile', data);
  }

  updateUserById(data: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/update?encrypted=' + this.encryptDecryptService.encryptParam(data.id), data);
  }

  approveUpdateUser(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/approve/update?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectUpdateUser(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/reject/update?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  updateClientById(data: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/client?encrypted=' + this.encryptDecryptService.encryptParam(data.id), data);
  }

  approveUpdateClient(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/approve/update/client?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectUpdateClient(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/reject/update/client?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  updatePassword(data): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/update-password', data);
  }

  getUserCountByUsername(username: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/users/count/username?encrypted=` + this.encryptDecryptService.encryptParam(username));
  }

  getUserCountByEmail(email: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/users/count/email?encrypted=` + this.encryptDecryptService.encryptParam(email));
  }

  createInvitedUser(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/invite', body);
  }

  createActiveUser(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/active', body);
  }

  sendSignUpLink(username: string): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/signup?encrypted=' + this.encryptDecryptService.encryptParam(username), {});
  }

  createClientUser(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/client', body);
  }

  syncUserWithKeycloak(): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/sync/keycloak', {});
  }

  resetPassword(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/reset-password', body);
  }

  checkValidUserSignup(username: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/signup/check?encrypted=' + this.encryptDecryptService.encryptParam(username));
  }

  setupUserPassword(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/setup-password', body);
  }

  setupInvitedUserPassword(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/setup-password/invite', body);
  }

  getBlockedUsersPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/blockedUsers', pageableRequest);
  }

  unblockUser(id: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/unblock?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  login(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/login', body);
  }

  refreshToken(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/refresh', body);
  }

  logoutUrl: string = '/users/logout';
  logout(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + this.logoutUrl);
  }

  checkValidUserActivatePasswordByUsername(username: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/activate-password/check?encrypted=' + this.encryptDecryptService.encryptParam(username));
  }

  activateUserPasswordByUsername(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/users/activate-password', body);
  }
}
