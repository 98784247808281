<div class="m-5">

  <div class="row">
    <div class="d-flex justify-content-center">
      <div class="col-lg-4">

        <div class="card">
          <div class="card-body">

            <form [formGroup]="peopleForm">

              <div class="form-group my-2">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name"
                  [ngClass]="{ 'is-invalid': submitClicked && peopleFormControls.name.errors }">
                <div class="invalid-feedback">
                  <div *ngIf="commonService.validationChecking('name', 'required', peopleForm, submitClicked)">
                    Please enter a valid name</div>
                </div>
              </div>

              <div class="form-group my-2">
                <label>Phone</label>
                <input type="text" class="form-control block-copy-paste" formControlName="phone"
                  [ngClass]="{ 'is-invalid': submitClicked && invalidPhoneOrEmail() || peopleFormControls.phone.errors }"
                  (keypress)="numberOnly($event)" (focus)="blockCopyPaste()">
                <div class="invalid-feedback">
                  <div *ngIf="invalidPhoneOrEmail()">
                    Please enter a valid phone or email</div>
                  <div *ngIf="commonService.validationChecking('phone', 'minlength', peopleForm, submitClicked)">
                    Phone must be between 10 to 11 digits</div>
                  <div *ngIf="commonService.validationChecking('phone', 'maxlength', peopleForm, submitClicked)">
                    Phone must be between 10 to 11 digits</div>
                </div>
              </div>

              <div class="form-group my-2">
                <label>E-Mail</label>
                <input type="text" class="form-control" formControlName="email"
                  [ngClass]="{ 'is-invalid': submitClicked && (invalidPhoneOrEmail() || peopleFormControls.email.errors) }">
                <div class="invalid-feedback">
                  <div *ngIf="invalidPhoneOrEmail()">
                    Please enter a valid phone or email</div>
                  <div *ngIf="commonService.validationChecking('email', 'email', peopleForm, submitClicked)">
                    Please enter a valid email format</div>
                </div>
              </div>

              <div class="form-group my-2">
                <label>City</label>
                <input type="text" class="form-control" formControlName="city"
                  [ngClass]="{ 'is-invalid': submitClicked && peopleFormControls.city.errors }">
                <div class="invalid-feedback">
                  <div *ngIf="commonService.validationChecking('city', 'required', peopleForm, submitClicked)">
                    Please enter a valid city</div>
                </div>
              </div>

              <div class="form-group my-2">
                <h6>Product</h6>
                <select name="select" id="select" class="bg-white w-100" style="height:35px;border-radius: 3px;" (change)="selectProduct($event.target.value)">
                  <option hidden>Please select a product</option>
                  <option *ngFor="let product of productsList" value="product.name">{{product.name}}</option>
                </select>
                <div *ngIf="peopleForm.get('product').value==''&&submitClicked" style="font-size:10px;color:#E1564D;margin-top:5px">Please select a product</div>
                
              </div>

              <button type="submit" class="btn btn-primary w-100" (click)="addLead(successContent)">Submit</button>
            </form>

          </div>
        </div>

      </div>
    </div>
  </div>

</div>


<!-- Success Modal -->
<ng-template #successContent let-modal>
  <div class="modal-body d-flex flex-column justify-content-center modal-alert">
    <div class="text-center mb-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
        class="feather feather-check-circle text-success">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
    </div>
    <h5 class="text-center font-weight-bold">Your response is submitted</h5>
    <div class="text-center">
      <button class="btn btn-success" (click)="modal.dismiss('Cross click')">Thank You</button>
    </div>
  </div>
</ng-template>