import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { FormSubmissionComponent } from './form-submission/form-submission.component';
import { LayoutComponent } from './layouts/layout.component';
import { LeadMicrositeComponent } from './lead-microsite/lead-microsite/lead-microsite.component';
import { ProductMatchingComponent } from './product-matching/component/product-matching.component';

const routes: Routes = [
  { path: '', redirectTo: 'account/login', pathMatch: 'full' },
  { path: 'account', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
  {
    path: 'lms',
    component: LayoutComponent,
    loadChildren: () => import('./lms/lms.module').then((m) => m.LMSModule),
    canActivate: [AuthGuard],
  },
  { path: 'product-matching', component: ProductMatchingComponent },
  { path: 'lead-microsite', component: LeadMicrositeComponent },
  { path: 'e-form/:code', component: FormSubmissionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
