import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as ESAPI from 'node-esapi';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  constructor() { }

  public encrypt(toEncrypt: any) {
    const keyBase64 = "k/FCt7weWipbCjFQo3T+e1ejP16tX7jt3HQ9eym/E5k=";
    var key = CryptoJS.enc.Base64.parse(keyBase64);
    var srcs = CryptoJS.enc.Utf8.parse(toEncrypt);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString();
  }

  public decrypt(toDecrypt: any) {
    const keyBase64 = "k/FCt7weWipbCjFQo3T+e1ejP16tX7jt3HQ9eym/E5k=";
    var key = CryptoJS.enc.Base64.parse(keyBase64);
    var decrypt = CryptoJS.AES.decrypt(toDecrypt, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return CryptoJS.enc.Utf8.stringify(decrypt);
  }

  public encryptParam(toEncrypt: any) {
    return this.encodeURI(this.encrypt(toEncrypt));
  }

  public encryptURL(toEncrypt: any) {
    return btoa(this.encrypt(toEncrypt));
  }

  public encodeURI(param: any) {
    return encodeURIComponent(param);;
    // return ESAPI.encoder().encodeForURL(param);
  }


}
