import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { Account } from 'src/app/state/account-action';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  highestParents: any[] = [];
  branchesId: any[] = [];

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private matSnackBar: MatSnackBar,
    private permissionsService: NgxPermissionsService,
    private store: Store
  ) {}

  public loginLoading: boolean = false;

  public validationChecking(fieldName: string, errorName: string, formGroup: FormGroup, submitClicked: boolean) {
    if (formGroup.get(fieldName).getError(errorName) && submitClicked) {
      return true;
    } else {
      return false;
    }
  }

  public filter(value: string, dataList: Array<any>, fieldName: string): Array<any> {
    const filterValue = value.toLowerCase();
    return dataList.filter((data) => (data[fieldName] as string).toLowerCase().includes(filterValue));
  }

  public getInitials(firstName: string, lastName: string = '') {
    const recipientFirstName = firstName?.split(' ');
    let recipientFNInitials: string;
    if (!recipientFirstName) {
      recipientFNInitials = '';
    } else {
      recipientFNInitials = recipientFirstName[0].charAt(0);
    }

    const recipientLastName = lastName?.split(' ');
    let recipientLNInitials: string;
    if (!recipientLastName) {
      recipientLNInitials = '';
    } else {
      recipientLNInitials = recipientLastName[0].charAt(0);
    }
    return recipientFNInitials.toUpperCase() + recipientLNInitials.toUpperCase();
  }

  public getRoleNameFromCompositeRealmRoleMapping(roleMappings: any[]) {
    let roleNames: string[] = [];
    roleMappings.forEach((element) => {
      roleNames.push(element.name);
    });
    return roleNames;
  }

  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '-';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public sessionLogout(toLoginPage: boolean) {
    this.loginLoading = false;
    this.modalService.dismissAll();
    this.permissionsService.flushPermissions();

    const payload: any = {
      tokenType: null,
      refreshToken: null,
      accessToken: null,
      userLoggedIn: false,
      permissions: [],
    };
    this.store.dispatch(new Account(payload)).subscribe(() => {
      if (toLoginPage) {
        this.router.navigate(['/account/login']);
      }
    });
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  public matSnackBarOpenSuccessMessage() {
    this.matSnackBarOpen('Data has been saved successfully');
  }

  public matSnackBarOpenDeleteMessage() {
    this.matSnackBarOpen('Data has been removed successfully');
  }

  public matSnackBarOpenErrorMessage() {
    this.matSnackBarOpen('An unexpected error has been occurred, please try again later');
  }

  public matSnackBarOpenInvalidPermissionMessage() {
    this.matSnackBarOpen('Forbidden Access');
  }

  public matSnackBarOpenNoChangedMessage() {
    this.matSnackBarOpen('There is no updated value!');
  }

  public matSnackBarFileNotAnImage() {
    this.matSnackBarOpen('File is not an image type! please upload an image file');
  }

  public matSnackBarFileImageExceed5MB() {
    this.matSnackBarOpen('Maximum allowed image file is not more than 5MB');
  }

  public matSnackBarFileNotACsv() {
    this.matSnackBarOpen('File is not an csv type! please upload an csv file');
  }

  public matSnackBarInvalidForm() {
    this.matSnackBarOpen('The form contains invalid data. Try again.');
  }

  public matSnackBarProductExist() {
    this.matSnackBarOpen('Product already exist! Please change the product name');
  }

  public matSnackBarNotAllowDeleteAtPendingStatus() {
    this.matSnackBarOpen('Not allow to delete the item at the pending status.');
  }

  public matSnackBarCampaignExpired() {
    this.matSnackBarOpen('This campaign is expired, please change the start date and end date to publish.');
  }

  public matSnackBarDateExceeded() {
    this.matSnackBarOpen('The from date cannot exceed the to date');
  }

  public matSnackBarOpen(message: string) {
    this.matSnackBar.open(message, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 10000,
    });
  }

  removeChild(branches: any[]) {
    for (let i = 0; i < branches?.length; i++) {
      if (branches[i].children.length <= 0) {
        delete branches[i].children;
        Object.assign(branches[i], { isLeaf: true });
        // console.log("Removed child for:" + branches[i].title);
        // console.log("The object:" + branches[i]);
      } else this.removeChild(branches[i].children);
    }
    return branches;
  }

  branchesIdMappingArray(branches: any[], branchId: number) {
    this.branchesId = [];
    for (let i = 0; i < branches?.length; i++) {
      if (branches[i].key === branchId) {
        this.branchesId.push(branchId);
        this.checkChildrenBranch(branches[i].children);
        break;
      } else this.branchesIdMappingArray(branches[i].children, branchId);
    }
    return this.branchesId;
  }

  checkChildrenBranch(branches: any[]) {
    for (let i = 0; i < branches?.length; i++) {
      this.branchesId.push(branches[i].key);
      if (branches[i].children?.length > 0) {
        this.checkChildrenBranch(branches[i].children);
      }
    }
  }

  getTagList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/common/tags/list');
  }

  getPermissionList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/common/permission/list');
  }

  getCountryList() {
    return this.httpClient.get(`${environment.baseUrl}/common/countries/list`);
  }

  getPhoneEmailTypeList() {
    return this.httpClient.get(`${environment.baseUrl}/common/phone-email-types/list`);
  }

  getEmailProviderList() {
    return this.httpClient.get(`${environment.baseUrl}/common/email-providers/list`);
  }

  getDealOpenStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/open`);
  }

  getDealWonStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/won`);
  }

  getDealLostStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/lost`);
  }

  getDealPendingOpenStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/pending-open`);
  }

  getDealPendingReassignStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/pending-reassign`);
  }

  getDealPendingWonStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/pending-won`);
  }

  getDealPendingLostStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/pending-lost`);
  }

  getDealPendingDeleteStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/deal/pending-delete`);
  }

  getActivityTodoStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/activity/todo`);
  }

  getProductActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/product/active`);
  }

  getProductPendingCreateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/product/pending-create`);
  }

  getProductPendingUpdateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/product/pending-update`);
  }

  getProductPendingDeleteStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/product/pending-delete`);
  }

  getBranchActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/branch/active`);
  }

  getBranchPendingCreateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/branch/pending-create`);
  }

  getBranchPendingUpdateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/branch/pending-update`);
  }

  getBranchPendingDeleteStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/branch/pending-delete`);
  }

  getGroupActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/group/active`);
  }

  getGroupPendingCreateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/group/pending-create`);
  }

  getGroupPendingUpdateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/group/pending-update`);
  }

  getGroupPendingDeleteStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/group/pending-delete`);
  }

  getLostReasonActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/lost-reason/active`);
  }

  getLostReasonPendingCreateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/lost-reason/pending-create`);
  }

  getLostReasonPendingUpdateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/lost-reason/pending-update`);
  }

  getLostReasonPendingDeleteStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/lost-reason/pending-delete`);
  }

  getJobSchedulerPendingUpdateStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/jobScheduler/pending-update`);
  }

  getJobSchedulerActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/jobScheduler/active`);
  }

  getJobSchedulerInActiveStatus() {
    return this.httpClient.get(`${environment.baseUrl}/common/status/jobScheduler/inactive`);
  }
}
