import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { Account } from "./account-action";

export interface AccountModel {
    accessToken: string,
    refreshToken: string,
    tokenType: string,
    userLoggedIn: boolean,
    permissions: string[],
    adminAccess: boolean,
    branchesId: number[],
    branches: any[],
};

@State<AccountModel>({
    name: 'userAccount',
    defaults: {
        accessToken: null,
        refreshToken: null,
        tokenType: null,
        userLoggedIn: false,
        permissions: [],
        adminAccess: false,
        branchesId: [],
        branches: []
    }
})
@Injectable()
export class AccountState {
    constructor() {
    }

    @Action(Account)
    public storeCredentials(
        { patchState }: StateContext<AccountModel>,
        { payload }: Account
    ) {
        return patchState({
            accessToken: payload.accessToken,
            refreshToken: payload.refreshToken,
            tokenType: payload.tokenType,
            userLoggedIn: payload.userLoggedIn,
            permissions: payload.permissions,
            adminAccess: payload.adminAccess,
            branchesId: payload.branchesId,
            branches: payload.branches
        });
    }
}