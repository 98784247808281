// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: false,
  baseUrl: 'https://leads.bankislam.com.my/api',
  eform: 'https://leads.bankislam.com.my',
  version: 'v2.1.12',
  keycloak: {
    url: 'https://d1isjhghszo6bn.cloudfront.net/auth/',
    realm: 'feng-test',
    grant_type: 'password',
    refresh_grant_type: 'refresh_token',
    client_id: 'dev-test',
    client_secret: 'l0EHoVfX7VSJH2iqHAFS5hnKSQhD5OZG',
  },
  firebase: {
    apiKey: 'AIzaSyA0mtjW6JXycRdbAsXJ2SYZyYdP18t05K4',
    authDomain: 'agro-lms.firebaseapp.com',
    projectId: 'agro-lms',
    storageBucket: 'agro-lms.appspot.com',
    messagingSenderId: '1016226836507',
    appId: '1:1016226836507:web:27a328030bc0965c2448be',
    vapidKey: 'BG0rtBKibBpb905QRYxgMaIX3awRusQPXG10D8StHDS54D7YTAcWZec7953AjhR27fvv3wWUzyG67IwZ-A_QTkA',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
