<div class="m-5">

  <form [formGroup]="productMatchingForm">
    <div class="row d-flex justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
        <h1 class="text-center">
          How may I help you?
          <!-- {{step}} -->
        </h1>
      </div>

      <div class="w-100"></div>

      <div class="col-sm-12 col-md-12 col-lg-6">
        <div class="row form-group mb-3">
          <label class="col-form-label text-center" *ngIf="step > bankingType.id && productMatchingDisplayForm.value.bankingType">
            {{bankingType.answer}}
            <span class="form-value"
              (click)="revertValue(bankingType.id)">{{productMatchingDisplayForm.value.bankingType}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > productType.id && productMatchingDisplayForm.value.productType">
            {{productType.answer}}
            <span class="form-value"
              (click)="revertValue(productType.id)">{{productMatchingDisplayForm.value.productType}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > purpose.id && productMatchingDisplayForm.value.purpose">
            {{purpose.answer}}
            <span class="form-value"
              (click)="revertValue(purpose.id)">{{productMatchingDisplayForm.value.purpose}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > gender.id && productMatchingDisplayForm.value.gender">
            {{gender.answer}}
            <span class="form-value" (click)="revertValue(gender.id)">{{productMatchingDisplayForm.value.gender}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > age.id && productMatchingDisplayForm.value.age">
            {{age.answer}}
            <span class="form-value" (click)="revertValue(age.id)">{{productMatchingDisplayForm.value.age}}</span>
          </label>
          <label class="col-form-label text-center"
            *ngIf="step > employmentStatus.id && productMatchingDisplayForm.value.employmentStatus">
            {{employmentStatus.answer}}
            <span class="form-value"
              (click)="revertValue(employmentStatus.id)">{{productMatchingDisplayForm.value.employmentStatus}}</span>
          </label>
          <label class="col-form-label text-center"
            *ngIf="step > businessSegment.id && productMatchingDisplayForm.value.businessSegment">
            {{businessSegment.answer}}
            <span class="form-value"
              (click)="revertValue(businessSegment.id)">{{productMatchingDisplayForm.value.businessSegment}}</span>
          </label>
          <label class="col-form-label text-center"
            *ngIf="step > businessNature.id && productMatchingDisplayForm.value.businessNature">
            {{businessNature.answer}}
            <span class="form-value"
              (click)="revertValue(businessNature.id)">{{productMatchingDisplayForm.value.businessNature}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > tenure.id && productMatchingDisplayForm.value.tenure">
            {{tenure.answer}}
            <span class="form-value" (click)="revertValue(tenure.id)">{{productMatchingDisplayForm.value.tenure}}</span>
          </label>
          <label class="col-form-label text-center" *ngIf="step > nationality.id && productMatchingDisplayForm.value.nationality">
            {{nationality.answer}}
            <span class="form-value"
              (click)="revertValue(nationality.id)">{{productMatchingDisplayForm.value.nationality}}</span>
          </label>
          <label class="col-form-label text-center"
            *ngIf="step > financingType.id && productMatchingDisplayForm.value.financingType">
            {{financingType.answer}}
            <span class="form-value"
              (click)="revertValue(financingType.id)">{{productMatchingDisplayForm.value.financingType}}</span>
          </label>
        </div>
      </div>

    </div>
  </form>

  <form [formGroup]="productMatchingForm">
    <div class="row d-flex justify-content-center">

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == bankingType.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{bankingType.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="bankingType"
                (change)="resetFormPartially('bankingType', bankingType.options)">
                <option *ngFor="let data of bankingType.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('bankingType', bankingType.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == productType.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == productType.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{productType.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="productType"
                (change)="resetFormPartially('productType', productType.options)">
                <option *ngFor="let data of productType.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('productType', productType.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == purpose.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == purpose.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{purpose.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="purpose">
                <option *ngFor="let data of purpose.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary" (click)="checkValueV2('purpose', purpose.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == gender.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == gender.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{gender.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="gender">
                <option *ngFor="let data of gender.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary" (click)="checkValueV2('gender', gender.options)">Next
              >></button>
          </div>

        </div>

      </div>

      <div class="w-100" *ngIf="step == age.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == age.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{age.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="age">
                <option *ngFor="let data of age.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary" (click)="checkValueV2('age', age.options)">Next >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == employmentStatus.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == employmentStatus.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{employmentStatus.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="employmentStatus">
                <option *ngFor="let data of employmentStatus.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('employmentStatus', employmentStatus.options)">Next >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == businessSegment.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == businessSegment.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{businessSegment.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="businessSegment">
                <option *ngFor="let data of businessSegment.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('businessSegment', businessSegment.options)">Next >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == businessNature.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == businessNature.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{businessNature.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="businessNature">
                <option *ngFor="let data of businessNature.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('businessNature', businessNature.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == tenure.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == tenure.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{tenure.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="tenure">
                <option *ngFor="let data of tenure.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary" (click)="checkValueV2('tenure', tenure.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == nationality.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == nationality.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{nationality.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="nationality">
                <option *ngFor="let data of nationality.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('nationality', nationality.options)">Next
              >></button>
          </div>

        </div>
      </div>

      <div class="w-100" *ngIf="step == financingType.id"></div>

      <div class="col-sm-12 col-md-12 col-lg-6 card" *ngIf="step == financingType.id">
        <div class="card-body">

          <div class="row form-group mb-3">
            <label class="col-form-label">{{financingType.question}}</label>
            <div class="col-12">
              <select class="form-select" formControlName="financingType">
                <option *ngFor="let data of financingType.options" value="{{data.id}}">{{data.name}}</option>
              </select>
            </div>
          </div>

          <div class="d-flex flex-row-reverse">
            <button type="button" class="btn btn-primary"
              (click)="checkValueV2('financingType', financingType.options)">Next
              >></button>
          </div>

        </div>
      </div>

    </div>
  </form>


  <div class="row d-flex justify-content-center" *ngIf="showRecommendationSection">
    <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
      <h1 class="text-center">Our Recommendation</h1>
    </div>

    <div class="w-100"></div>

    <div class="spinner-border text-primary" role="status" *ngIf="recommendedProducts.length == 0">
      <span class="sr-only">Loading...</span>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-4 p-3" *ngFor="let data of recommendedProducts">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title text-center">{{data.name}}</h4>
          <h6 class="card-subtitle mb-2 text-muted text-center">{{data.subtitle}}</h6>
          <p class="card-text text-justify">{{data.description}}</p>

          <div class="d-flex justify-content-center">
            <div class="col-sm-12 col-md-6 col-lg-8">
              <button type="button" class="btn btn-primary w-100 mb-2" (click)="contactUs()">Contact Us</button>
              <button type="button" class="btn btn-primary w-100 mb-2" (click)="discoverMore()">Discover More</button>
              <button type="button" class="btn btn-primary w-100 mb-2" (click)="applyNow()">Apply Now</button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="w-100"></div>

    <div class="col-sm-12 col-md-12 col-lg-6" *ngIf="recommendedProducts.length > 0">
      <h3 class="text-center">Not what you need?</h3>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-primary" (click)="research()">Search Again</button>
      </div>
    </div>

  </div>

</div>