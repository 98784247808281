import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbAccordionModule, NgbModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { BnNgIdleService } from 'bn-ng-idle';
import { CKEditorModule } from 'ng2-ckeditor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DecryptInterceptor } from './core/helpers/decrypt.interceptor';
import { EncryptInterceptor } from './core/helpers/encrypt.interceptor';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { TokenInterceptor } from './core/helpers/token.interceptor';
import { FormSubmissionModule } from './form-submission/form-submission.module';
import { LayoutsModule } from './layouts/layouts.module';
import { LeadMicrositeModule } from './lead-microsite/lead-microsite.module';
import { ProductMatchingModule } from './product-matching/product-matching.module';
import { AccountState } from './state/account-state';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],

  imports: [
    CKEditorModule,
    ProductMatchingModule,
    FormSubmissionModule,
    LeadMicrositeModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CKEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    NgxsModule.forRoot([AccountState]),
    NgxsLoggerPluginModule.forRoot({
      disabled: true,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: 1,
    }),
    NgxQRCodeModule,
    MatTableModule,
    NgxPermissionsModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireAuthModule
  ],
  bootstrap: [AppComponent],
  providers: [
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncryptInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DecryptInterceptor, multi: true },
  ],
})
export class AppModule {}
