import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { CommonService } from 'src/app/lms/common/common.service';
import { UserService } from 'src/app/lms/users-management/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private permissionsService: NgxPermissionsService,
    private store: Store
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    this.commonService.loginLoading = true;

    const refreshToken = this.store.selectSnapshot<string>((state) => state.userAccount.refreshToken);
    const res = await this.userService.refreshToken({ refreshToken: refreshToken }).toPromise();
    if (!res.loginSuccess) {
      this.commonService.sessionLogout(true);
    }

    let userPermissions: string[] = this.store.selectSnapshot<string[]>((state) => state.userAccount.permissions);
    if (userPermissions.length <= 0) {
      this.commonService.sessionLogout(true);
    }

    let componentPermissions: string[] = [];
    Object.keys(route.data).forEach((key) => {
      componentPermissions.push(route.data[key]);
    });

    let hasPermission: boolean = await this.permissionsService.hasPermission(componentPermissions);

    if (hasPermission !== true) {
      this.permissionsService.loadPermissions(userPermissions);
      hasPermission = await this.permissionsService.hasPermission(componentPermissions);
    }

    if (hasPermission !== true) {
      this.commonService.sessionLogout(true);
    }

    this.commonService.loginLoading = false;
    return hasPermission;
  }
}
