export enum KEYNAME {
    BANKING_TYPE,
    PRODUCT_TYPE,
    PURPOSE,
    GENDER,
    AGE,
    EMPLOYMENT_STATUS,
    BUSINESS_SEGMENT,
    BUSINESS_NATURE,
    TENURE,
    NATIONALITY,
    FINANCING_TYPE
}

export enum OCCUR {
    SHOULD,
    MUST
}