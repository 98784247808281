import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(
    private httpClient: HttpClient,
    private encryptDecryptService: EncryptDecryptService
  ) { }

  getBranchPage(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/hierarchy');
  }

  getBranchById(id: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/get?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getAllBranchList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/hierarchy/all');
  }

  getBranchList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/hierarchy/viewable');
  }

  // getFilteredBranchList(): Observable<any> {
  //   return this.httpClient.get(environment.baseUrl + '/branches/list/hierarchy/filtered');
  // }

  getCustomerStateList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/customer/state');
  }

  getCustomerBranchByStateAsList(id: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/customer/branch?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getBranchListByDefaultParentId(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/parent/default');
  }

  getBranchListByParentId(id: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/parent?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getBranchListAndExcludedId(id: number = 0): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/exclude?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getGroupsByBranchId(ids: number[]): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/branches/list/group', { params: { branchIds: ids } });
  }

  createBranch(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/branches', body);
  }

  updateBranch(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/update?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  deleteBranch(id: number): Observable<any> {
    return this.httpClient.delete(environment.baseUrl + '/branches/delete?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  approveCreateBranch(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/approve/create?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveUpdateBranch(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/approve/update?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveDeleteBranch(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/approve/delete?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectCreateBranch(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/reject/create?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  rejectUpdateBranch(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/reject/update?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  rejectDeleteBranch(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/branches/reject/delete?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }
}
