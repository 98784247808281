export const PasswordConfig = {
  pattern: '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^ -/:-@[-`{-~]*[ -/:-@[-`{-~]).{1,}',
  minLength: 8,
  instruction:
    'Password must be at least 8 characters with the combination of at least one upper character, one lower character, one number and one special character',
  show: {
    inputType: 'text',
    icon: 'mdi mdi-eye-outline',
  },
  hide: {
    inputType: 'password',
    icon: 'mdi mdi-eye-off-outline',
  },
};

export const PdpaConfig = {
  acknowledge1:
    "I hereby declare that all the above information given by me is correct and complete in compliance with the Personal Data Protection Act 2010, I hereby give my consent to the Bank to collect, process, and continue to use the information disclosed by me for a maximum period of one month from the date this form is submitted for the purpose of this application, cross selling, marketing, and promotions whatsoever including offers and competitions and disclosure my information within BIMB holding group of companies, subsidiaries, its agent, employees, trustee, and/or to any other person or entity which the Bank's deems appropriate in accordance with the <a href='https://www.bankislam.com/wp-content/uploads/BankIslam-PrivacyNotice_NotisPrivasi.pdf' target='_blank'>Bank Islam Group Privacy Notice</a> which is available at our branches and the Bank's website at <a href='https://www.bankislam.com' target='_blank'>www.bankislam.com.</a>",
  acknowledge2:
    "I hereby acknowledge that I have read, understood, and agree with the terms, conditions, rules, guidelines, and regulations as stated in the product's Terms and Conditions and/or Product Disclosure Sheet (whichever is applicable) provided in the Bank's website at www.bankislam.com.",
  onBehalf:
    'Note: Please ensure consent has been obtained from customer to submit his/her information for Bank Islam to process and keep for cross selling Bank Islam products or services for maximum of 1 month. Please be informed that the introducer name will be disclosed to customer. If the consent has not been obtained or you do not agree to the disclosure. DO NOT PROCEED.',
  agree: 'I agree on above declaration.',
};

export const ValidatorPattern = {
  ICNumber: '[0-9]{12}',
  BusinessRegistrationNumber: '[0-9a-zA-Z]{12}$|^\\d{6}-[a-zA-Z]',
  NumberOnly: '[0-9]{1,}',
  PassportNumber: '[0-9a-zA-Z]{9,15}',
  PoliceNumber: '^[a-zA-Z][0-9a-zA-Z]{2}\\d{7}$',
  MilitaryNumber: '^[0-9a-zA-Z]\\d{4,9}$',
};

export const PhoneConfig = {
  pattern: '^[0-9]{7,12}$',
  example: 'Eg: 60123456789',
};

export const EmailConfig = {
  example: 'Eg: name@email.com',
};

export const BranchTreeSelectConfig = {
  hideUnmatched: true,
  showSearch: true,
  placeHolder: 'Please choose a branch',
  notFoundContent: 'No Branch Found',
  dropdownMatchSelectWidth: false,
  dropdownStyle: { height: '400px', width: '400px' },
  defaultExpandAll: true,
};

export const DateFormat: string = 'dd/MM/yyyy';

// unsupported toolbar items are commented out
export const CkEditor5Config = {
  toolbar: [
    'heading',
    // '|',
    // 'fontfamily',
    // 'fontsize',
    // 'fontColor',
    // 'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    // 'strikethrough',
    // 'subscript',
    // 'superscript',
    // 'code',
    '|',
    'link',
    'blockQuote',
    // 'codeBlock',
    'insertTable',
    '|',
    'bulletedList',
    'numberedList',
    // 'todoList',
    'outdent',
    'indent',
    '|',
    'undo',
    'redo',
  ],
};
