import { AfterViewInit, Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subscription, interval, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventService } from '../core/services/event.service';
import { CommonService } from '../lms/common/common.service';
import { UserService } from '../lms/users-management/user.service';
import { Account } from '../state/account-action';
import { AccountModel } from '../state/account-state';
import { LAYOUT_HORIZONTAL, LAYOUT_VERTICAL, LAYOUT_WIDTH, TOPBAR } from './layouts.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('idleSwal')
  public readonly idleSwal!: SwalComponent;

  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  interval: any;
  countDown: Subscription;
  counter: number = 30;

  constructor(private eventService: EventService, public commonService: CommonService, private store: Store, private userService: UserService) {}

  async ngOnInit() {
    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });

    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    this.getUserLogginSession();
    this.triggerUserIdleSession();
  }

  ngAfterViewInit() {}

  LayoutWidth(width: string) {
    switch (width) {
      case 'fluid':
        document.body.setAttribute('data-layout-size', 'fluid');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'boxed':
        document.body.setAttribute('data-layout-size', 'boxed');
        document.body.classList.add('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'scrollable':
        document.body.removeAttribute('data-layout-size');
        document.body.setAttribute('data-layout-scrollable', 'true');
        document.body.setAttribute('data-layout-size', 'fluid');
        document.body.classList.remove('right-bar-enabled', 'vertical-collpsed');
      default:
        document.body.setAttribute('data-layout-size', 'fluid');
        break;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  getUserLogginSession() {}

  triggerUserIdleSession() {
    if (this.interval) {
      this.interval.unsubscribe();
    }

    if (this.countDown) {
      this.countDown.unsubscribe();
    }

    const userLoggedIn = this.store.selectSnapshot<boolean>((state) => state?.userAccount?.userLoggedIn);
    if (userLoggedIn) {
      const accessToken = this.store.selectSnapshot<string>((state) => state.userAccount.accessToken);
      const accessExpiry = JSON.parse(atob(accessToken.split('.')[1])).exp;
      const accessIssuedAt = JSON.parse(atob(accessToken.split('.')[1])).iat;
      const currentDate: number = new Date().getTime() / 1000;

      // minus 60 second to prevent token expired before get to refresh
      let accessSession = (accessExpiry - accessIssuedAt - 60) * 1000;

      // if access session is less than 40s, set its value to exactly 40s
      // anything else less than that will interfere with the idle-pop-up's behaviour
      if (accessSession < 40000) {
        accessSession = 40000;
      }

      if (!environment.production) {
        console.log('accessIssuedAt', accessIssuedAt);
        console.log('accessExpiry  ', accessExpiry);
        console.log("Length In Miliseconds", accessExpiry - accessIssuedAt);
        console.log('currentDate   ', currentDate);
      }
      console.log('Idle Popup in', accessSession / 60000, 'mins');

      this.interval = interval(accessSession).subscribe(() => {
        this.getUserLogginSession();
        // console.log('Idle Popup left', accessSession / 60000, 'mins');
        const userLoggedIn = this.store.selectSnapshot<boolean>((state) => state?.userAccount?.userLoggedIn);
        if (userLoggedIn) {
          this.showWarningMessage();
        } else {
          console.log('No idle popup!');
        }

        stop();
      });
    }
  }

  async logout() {
    await this.userService
      .logout()
      .toPromise()
      .catch((error) => {
        return null;
      });

    this.commonService.sessionLogout(true);
    if (this.interval) {
      this.interval.unsubscribe();
    }

    if (this.countDown) {
      this.countDown.unsubscribe();
    }
  }

  async refreshToken() {
    const refreshToken = this.store.selectSnapshot<string>((state) => state.userAccount.refreshToken);

    let request = {
      refreshToken: refreshToken,
    };

    const res = await this.userService.refreshToken(request).toPromise();

    if (res.loginSuccess) {
      let userAccount = this.store.selectSnapshot<AccountModel>((state) => state?.userAccount);
      userAccount.accessToken = res.accessToken;
      userAccount.refreshToken = res.refreshToken;
      userAccount.userLoggedIn = true;
      this.store.dispatch(new Account(userAccount));
      this.triggerUserIdleSession();
    } else {
      this.commonService.matSnackBarOpen('Error getting your token');
      this.commonService.sessionLogout(true);
      this.interval.unsubscribe();
      this.countDown.unsubscribe();
    }
  }

  showWarningMessage() {
    if (this.countDown) {
      this.countDown.unsubscribe();
    }
    this.counter = 30;
    this.countDown = timer(0, 1000).subscribe(() => --this.counter);
    this.idleSwal.fire().then((reason) => {
      if (reason.dismiss?.toString() == 'timer') {
        this.logout();
      }
    });
  }
}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    return ('00' + Math.floor(value)).slice(-2);
  }
}
