import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ProductMatchingComponent } from './component/product-matching.component';


@NgModule({
  declarations: [
    ProductMatchingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule
  ],
  bootstrap: [ProductMatchingComponent]
})
export class ProductMatchingModule { }
