import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BranchTreeSelectConfig, EmailConfig, PdpaConfig, PhoneConfig, ValidatorPattern } from '../app.config';
import { CommonService } from '../lms/common/common.service';
import { COMPANY_TYPE, ID_TYPE, LEAD_TYPE, SALUTATION, SECTOR } from '../lms/enum/lms-type.enum';
import { IForm, IFormFieldCustom, IMarketingCodeResponse, IMarketingCreateResponse, IProductBase } from '../lms/leads/interfaces/form.interface';
import { LeadGroupService } from '../lms/leads/lead-group.service';
import { MarketingService } from '../lms/leads/marketing.service';
import { ProductsService } from '../lms/products/products.service';
import { BranchService } from '../lms/users-management/branch.service';

@Component({
  selector: 'app-form-submission',
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.scss'],
})
export class FormSubmissionComponent implements OnInit {
  marketingDetails: IMarketingCodeResponse = {} as IMarketingCodeResponse;
  productTypeList: any[] = [];
  productList: IProductBase[] = [];
  formDetails: IForm = {} as IForm;
  formFieldCustomList: IFormFieldCustom[] = [];

  isLoading: boolean = false;
  isUnavailable: boolean = false;
  submitClicked: boolean = false;
  idValidation: boolean = false;

  lockLeadType: boolean = true;
  financingId: number = 0;
  pdpaAgree: boolean = false;
  pdpaConfig = PdpaConfig;

  form = new FormGroup({
    leadType: new FormControl(null, Validators.required),
    marketingId: new FormControl(null, Validators.required),
    productTypeId: new FormControl(null),
  });

  stateList: any[] = [];
  branchList: any[] = [];
  branchTree: any[] = [];
  BranchTreeSelectConfig = BranchTreeSelectConfig;
  countryList;
  leadGroupCompanyList;
  leadGroupIndividualList;
  leadTypeList: string[] = [LEAD_TYPE[LEAD_TYPE.INDIVIDUAL], LEAD_TYPE[LEAD_TYPE.COMPANY]];
  idTypeList: string[] = [ID_TYPE[ID_TYPE.IC_NO], ID_TYPE[ID_TYPE.MILITARY], ID_TYPE[ID_TYPE.PASSPORT], ID_TYPE[ID_TYPE.POLICE]];
  companyTypeList: string[] = [
    COMPANY_TYPE[COMPANY_TYPE.SOLE_SSM],
    COMPANY_TYPE[COMPANY_TYPE.SOLE_EAST],
    COMPANY_TYPE[COMPANY_TYPE.PARTNERSHIP_SSM],
    COMPANY_TYPE[COMPANY_TYPE.PARTNERSHIP_EAST],
    COMPANY_TYPE[COMPANY_TYPE.LIMITED],
    COMPANY_TYPE[COMPANY_TYPE.PRIVATE],
    COMPANY_TYPE[COMPANY_TYPE.PUBLIC],
  ];
  sectorList: string[] = [SECTOR[SECTOR.MANUFACTURING], SECTOR[SECTOR.SERVICES]];
  salutationList: string[] = [
    SALUTATION[SALUTATION.DATIN],
    SALUTATION[SALUTATION.DATO],
    SALUTATION[SALUTATION.DATO_SERI],
    SALUTATION[SALUTATION.DATUK],
    SALUTATION[SALUTATION.DATUK_SERI],
    SALUTATION[SALUTATION.DR],
    SALUTATION[SALUTATION.MISS],
    SALUTATION[SALUTATION.MR],
    SALUTATION[SALUTATION.MRS],
    SALUTATION[SALUTATION.PROF],
    SALUTATION[SALUTATION.TAN_SRI],
    SALUTATION[SALUTATION.TS],
    SALUTATION[SALUTATION.TUN],
  ];

  failModalMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private marketingService: MarketingService,
    public commonService: CommonService,
    private branchService: BranchService,
    private leadGroupService: LeadGroupService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private productService: ProductsService
  ) {
    translate.setDefaultLang('en');
  }

  async ngOnInit() {
    this.isLoading = true;
    this.stateList = await this.branchService.getCustomerStateList().toPromise();
    // this.branchList = await this.branchService.getCustomerBranchList().toPromise();
    // this.branchTree = this.commonService.removeChild(branchList);
    this.countryList = await this.commonService.getCountryList().toPromise();
    this.leadGroupIndividualList = await this.leadGroupService.getAsList(LEAD_TYPE.INDIVIDUAL).toPromise();
    this.leadGroupCompanyList = await this.leadGroupService.getAsList(LEAD_TYPE.COMPANY).toPromise();

    let leadType: string | null = this.activatedRoute.snapshot.queryParamMap.get('lt');
    switch (leadType) {
      case 'i':
        this.form.controls['leadType'].setValue(LEAD_TYPE[LEAD_TYPE.INDIVIDUAL]);
        break;
      case 'c':
        this.form.controls['leadType'].setValue(LEAD_TYPE[LEAD_TYPE.COMPANY]);
        break;
    }

    await this.setFormFields();
    this.isLoading = false;
  }

  get formControls() {
    return this.form.controls;
  }

  async getProductByType() {
    this.productList = await this.productService.getProductListByProductType(this.form.value.productTypeId).toPromise();
  }

  async setFormFields() {
    this.submitClicked = false;

    let code: string | null = this.activatedRoute.snapshot.paramMap.get('code');
    if (code) {
      this.marketingDetails = await this.marketingService.getChannelByCode(code).toPromise();
      if (!this.marketingDetails) {
        this.isLoading = false;
        this.isUnavailable = true;
        return;
      }
      this.formDetails = this.marketingDetails.form;
      this.form.patchValue({
        marketingId: this.marketingDetails.id,
      });
      this.productList = this.marketingDetails.productList;

      if (this.marketingDetails.type === 'CAMPAIGN') {
        this.productTypeList = await this.productService.getProductTypeDropdownList().toPromise();
      } else {
        this.productTypeList.push(this.marketingDetails.productType);
        this.form.get('productTypeId').setValue(this.marketingDetails.productType.id + '');
      }

      this.productTypeList.forEach((element) => {
        if (element.name === 'Financing Facility') {
          this.financingId = element.id;
        }
      });
    }

    Object.keys(this.form.controls).forEach((key) => {
      if (key === 'leadType' || key === 'marketingId' || key === 'productTypeId') {
        return;
      }
      this.form.removeControl(key);
    });

    this.formFieldCustomList = [];

    if (this.form.controls['leadType'].invalid) {
      return;
    }

    this.formDetails?.field.forEach((field) => {
      if (field.view && field.formFieldDefault.type === this.form.value.leadType) {
        this.formFieldCustomList.push(field);
      }
    });

    // sort by priority
    this.formFieldCustomList.sort(function (a, b) {
      return a.formFieldDefault.priority - b.formFieldDefault.priority;
    });

    this.formFieldCustomList.forEach((field) => {
      this.form.addControl(field.formFieldDefault.variableName, new FormControl(null));

      switch (field.formFieldDefault.variableSubType) {
        case 'email':
          this.form.controls[field.formFieldDefault.variableName].addValidators(Validators.email);
          field.formFieldDefault.example = EmailConfig.example;
          break;
        case 'ic_number':
          this.idValidation = true;
          break;
        case 'phone_number':
          this.form.controls[field.formFieldDefault.variableName].addValidators(Validators.pattern(PhoneConfig.pattern));
          field.formFieldDefault.example = PhoneConfig.example;
          break;
        case 'number_only':
          this.form.controls[field.formFieldDefault.variableName].addValidators(Validators.pattern(ValidatorPattern.NumberOnly));
          break;
        case 'business_registration_number':
          this.form.controls[field.formFieldDefault.variableName].addValidators(Validators.pattern(ValidatorPattern.BusinessRegistrationNumber));
          break;
        case 'branch_list':
          this.form.addControl("stateId", new FormControl(null));
          break;
      }

      if (field.required || field.formFieldDefault.mandatory) {
        this.form.controls[field.formFieldDefault.variableName].addValidators(Validators.required);
        if(field.formFieldDefault.variableName == "branchId"){
          this.form.controls["stateId"].addValidators(Validators.required);
        }
      }
    });

    console.log(this.form.controls["branchId"]);

    let productCode: string | null = this.activatedRoute.snapshot.queryParamMap.get('p');
    if (productCode) {
      this.productList.forEach((product: IProductBase) => {
        if (product.productCode === productCode && this.form.get('productIds')) {
          this.form.controls['productIds'].setValue([product.id + '']);
        }
      });
    }
  }

  showField(variableName) {
    if (variableName !== 'purposeOfFinancing' && variableName !== 'branchId') {
      return true;
    }

    return variableName === 'purposeOfFinancing' && this.form.value.productTypeId === this.financingId + '';
  }

  async filterBranches(stateId) {
    this.form.patchValue({
      branchId: null
    });

    this.branchList = await this.branchService.getCustomerBranchByStateAsList(stateId).toPromise();

  }

  checkValidation(value) {
    if (value == "IC_NO" || value == "MILITARY" || value == "PASSPORT" || value == "POLICE") {
      this.form.controls["idNo"].clearValidators();
      switch (value) {
        case 'IC_NO':
          this.form.controls["idNo"].addValidators(Validators.pattern(ValidatorPattern.ICNumber));
          this.formFieldCustomList.forEach((field) => {
            if (field.formFieldDefault.variableName === 'idNo') {
              field.formFieldDefault.example = 'Eg: 880525094356';
            }
          });
          break;
        case 'MILITARY':
          this.form.controls["idNo"].addValidators(Validators.pattern(ValidatorPattern.MilitaryNumber));
          this.formFieldCustomList.forEach((field) => {
            if (field.formFieldDefault.variableName === 'idNo') {
              field.formFieldDefault.example = 'Eg: 243235';
            }
          });
          break;
        case 'PASSPORT':
          this.form.controls["idNo"].addValidators(Validators.pattern(ValidatorPattern.PassportNumber));
          this.formFieldCustomList.forEach((field) => {
            if (field.formFieldDefault.variableName === 'idNo') {
              field.formFieldDefault.example = 'Eg: M12345678';
            }
          });
          break;
        case 'POLICE':
          this.form.controls["idNo"].addValidators(Validators.pattern(ValidatorPattern.PoliceNumber));
          this.formFieldCustomList.forEach((field) => {
            if (field.formFieldDefault.variableName === 'idNo') {
              field.formFieldDefault.example = 'Eg: AB12345678';
            }
          });
          break;
      }

      this.form.controls["idNo"].addValidators(Validators.required);
      this.form.controls["idNo"].reset();
      this.form.controls["idNo"].updateValueAndValidity();
    }
  }

  async submit(successModal, failModal) {
    this.submitClicked = true;
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.productIds) {
      this.form.value.productIds = [];
    }
    let res: IMarketingCreateResponse = {} as IMarketingCreateResponse;
    this.isLoading = true;
    if (this.form.value.leadType === LEAD_TYPE[LEAD_TYPE.INDIVIDUAL]) {
      res = await this.marketingService
        .createIndividual(this.form.value)
        .toPromise()
        .catch((error) => {
          return {
            personId: 0,
            companyId: 0,
            errorMessage: 'Your response is not submitted',
          };
        });
      this.openModal(res, successModal, failModal);
    }
    if (this.form.value.leadType === LEAD_TYPE[LEAD_TYPE.COMPANY]) {
      res = await this.marketingService
        .createCompany(this.form.value)
        .toPromise()
        .catch((error) => {
          return {
            personId: 0,
            companyId: 0,
            errorMessage: 'Your response is not submitted',
          };
        });
      this.openModal(res, successModal, failModal);
    }
    this.isLoading = false;
  }

  async openModal(res: IMarketingCreateResponse, successModal, failModal) {
    console.log(res);
    if (res.companyId > 0 || res.personId > 0) {
      this.modalService.open(successModal);
      this.submitClicked = false;
      this.form.reset();
      let leadType: string | null = this.activatedRoute.snapshot.queryParamMap.get('lt');
      switch (leadType) {
        case 'i':
          this.form.controls['leadType'].setValue(LEAD_TYPE[LEAD_TYPE.INDIVIDUAL]);
          break;
        case 'c':
          this.form.controls['leadType'].setValue(LEAD_TYPE[LEAD_TYPE.COMPANY]);
          break;
      }

      await this.setFormFields();
    } else {
      this.failModalMessage = res.errorMessage;
      this.modalService.open(failModal);
    }
  }
}
