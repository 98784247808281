import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomDatasource } from 'src/app/components/custom-data.interface';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';
import { PeopleRequest } from './interfaces/people-request.interface';
import { UpdateAddressRequest } from './interfaces/update-address-request.interface';
import { UpdateDetailsRequest } from './interfaces/update-details-request.interface';
import { UpdateFollowerRequest } from './interfaces/update-follower-request.interface';
import { PersonOrganizationRequest } from './interfaces/update-person-organization-request.interface';

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  getPage(pageableRequest): Observable<CustomDatasource> {
    return this.httpClient.post<CustomDatasource>(`${environment.baseUrl}/person/page`, pageableRequest);
  }

  getPageByProductId(pageableRequest): Observable<CustomDatasource> {
    return this.httpClient.post<CustomDatasource>(`${environment.baseUrl}/deals/page/product/people`, pageableRequest);
  }

  getDetailsById(id: string) {
    return this.httpClient.get(`${environment.baseUrl}/person/get?encrypted=` + this.encryptDecryptService.encryptParam(id));
  }

  getFollowersById(id: number, pageableRequest) {
    return this.httpClient.get(`${environment.baseUrl}/person/followers/page?encrypted=` + this.encryptDecryptService.encryptParam(id), {
      params: pageableRequest,
    });
  }

  updatePeopleContact(requestBody: any, id: number) {
    return this.httpClient.put(`${environment.baseUrl}/person/contact?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  updateAddress(requestBody: UpdateAddressRequest, id: number) {
    return this.httpClient.put(`${environment.baseUrl}/person/address?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  updateDetails(requestBody: UpdateDetailsRequest, id: number) {
    return this.httpClient.put(`${environment.baseUrl}/person/details?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  updatePeopleOrganizations(requestBody: Array<PersonOrganizationRequest>, id: number) {
    return this.httpClient.put(`${environment.baseUrl}/person/organizations?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  updateFollowers(requestBody: UpdateFollowerRequest, id: number) {
    return this.httpClient.put(`${environment.baseUrl}/person/followers?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  deleteTag(peopleId: number, tagId: number) {
    return this.httpClient.delete(
      `${environment.baseUrl}/person/tag?encryptedPeopleId=` +
        this.encryptDecryptService.encryptParam(peopleId) +
        `&encryptedTagId=` +
        this.encryptDecryptService.encryptParam(tagId),
      {}
    );
  }

  addTag(peopleId: number, tagId: number) {
    return this.httpClient.post(
      `${environment.baseUrl}/person/tag?encryptedPeopleId=` +
        this.encryptDecryptService.encryptParam(peopleId) +
        `&encryptedTagId=` +
        this.encryptDecryptService.encryptParam(tagId),
      {}
    );
  }

  getPeopleListing(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/person/list`);
  }

  getPeopleDropdownList(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/person/page/basic', pageableRequest);
  }

  addPeople(requestBody: PeopleRequest): Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUrl}/person/add`, requestBody);
  }

  addPeopleFromMicrosite(requestBody: PeopleRequest) {
    return this.httpClient.post(`${environment.baseUrl}/person/add/microsite`, requestBody);
  }

  updatePeople(requestBody: PeopleRequest, id: number): Observable<number> {
    return this.httpClient.put<number>(`${environment.baseUrl}/person/update?encrypted=` + this.encryptDecryptService.encryptParam(id), requestBody);
  }

  getNote(id: string, body: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/person/note?encrypted=` + this.encryptDecryptService.encryptParam(id), body);
  }

  updateNote(id: string, body: any) {
    return this.httpClient.put(`${environment.baseUrl}/person/note?encrypted=` + this.encryptDecryptService.encryptParam(id), body);
  }

  deleteNote(id: string, noteId: number) {
    return this.httpClient.delete(
      `${environment.baseUrl}/person/note?encryptedId=` +
        this.encryptDecryptService.encryptParam(id) +
        `&encryptedNoteId=` +
        this.encryptDecryptService.encryptParam(noteId)
    );
  }

  downloadTemplate(): Observable<HttpResponse<Blob>> {
    return this.httpClient.get<Blob>(environment.baseUrl + '/person/download/template', { observe: 'response', responseType: 'blob' as 'json' });
  }

  addBulkPepople(file: any): Observable<HttpResponse<Blob>> {
    return this.httpClient.post<Blob>(environment.baseUrl + '/person/add-bulk', file, { observe: 'response', responseType: 'blob' as 'json' });
  }
}
