<!-- vertical layout -->
<app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

<!-- horizontal layout -->
<app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>

<swal
  #idleSwal
  title="Your session is going to end!"
  text="Do you wish to continue?"
  icon="warning"
  confirmButtonText="Stay"
  denyButtonText="Logout ( {{counter | formatTime}} )"
  [timer]="30000"
  (confirm)="refreshToken()"
  (deny)="logout()"
  [allowOutsideClick]="false"
  [timerProgressBar]="true"
  [showDenyButton]="true"
  [focusCancel]="true">
  
</swal>