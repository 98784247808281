import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../../common/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public static Token: string = '';
  public static LastMessage: Subject<any> = new Subject<any>();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpClient: HttpClient,
    private encryptDecryptService: EncryptDecryptService
  ) {}

  public getNotificationsPage(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/page';

    return this.httpClient.get<any>(url, { params: body });
  }

  public getNotificationsBellPage(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/page/bell';

    return this.httpClient.get<any>(url, { params: body });
  }

  public getNotificationCount(): Observable<any> {
    let url: string = environment.baseUrl + '/notification/count';

    return this.httpClient.get<any>(url);
  }

  public readNotificationsById(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/read';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.put<any>(url, {});
  }

  public getNotificationEventList(): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event/list';

    return this.httpClient.get<any>(url);
  }

  public updateNotificationEvent(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event';

    return this.httpClient.put<any>(url, body);
  }

  public getNotificationChannelList(): Observable<any> {
    let url: string = environment.baseUrl + '/notification/channel/list';

    return this.httpClient.get<any>(url);
  }

  public getAllNotificationTemplatesByNotificationEventId(
    id: number
  ): Observable<any> {
    let url: string = environment.baseUrl + '/notification/template/list';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.get<any>(url);
  }

  public createAllNotificationTemplatesById(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/template';

    return this.httpClient.post<any>(url, body);
  }

  public updateAllNotificationTemplatesById(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/template';

    return this.httpClient.put<any>(url, body);
  }

  public createNotificationEventGroup(body: any): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event-group';

    return this.httpClient.post<any>(url, body);
  }

  public getGroupsByNotificationId(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event-group/list';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.get<any>(url);
  }

  public getTmpGroupsByNotificationId(id: number): Observable<any> {
    let url: string =
      environment.baseUrl + '/notification/event-group/tmp/list';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.get<any>(url);
  }

  public deleteGroupByNotificationEventGroup(
    notificationid: number,
    groupid: string
  ): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event-group';
    url +=
      '?encryptedNotificationId=' +
      this.encryptDecryptService.encryptParam(notificationid);
    url +=
      '&encryptedGroupId=' + this.encryptDecryptService.encryptParam(groupid);

    return this.httpClient.delete<any>(url);
  }

  public assignGroupToTemporary(
    body: any,
    notificationid: number
  ): Observable<any> {
    let url: string = environment.baseUrl + '/notification/event-group';
    url +=
      '?encryptedId=' + this.encryptDecryptService.encryptParam(notificationid);

    return this.httpClient.put<any>(url, body);
  }

  public rejectNotificationSettings(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/reject';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.put<any>(url, {});
  }

  public approveNotificationSettings(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/approve';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.put<any>(url, {});
  }

  public rejectNotificationTemplate(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/reject/template';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.put<any>(url, {});
  }

  public approveNotificationTemplate(id: number): Observable<any> {
    let url: string = environment.baseUrl + '/notification/approve/template';
    url += '?encryptedId=' + this.encryptDecryptService.encryptParam(id);

    return this.httpClient.put<any>(url, {});
  }

  public initListener() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.info('(debug) token received', token);
        if (token) {
          NotificationService.Token = token;
        }
      },
      (err) => {
        console.error('(debug) token retrieval error', err);
      }
    );
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
        console.info('(debug) message received', msg);
        NotificationService.LastMessage.next(msg);
      },
      (err) => {
        console.error('(debug) message retrieval error', err);
      }
    );
  }

  // testMessaging(): Observable<any> {
  //   console.info('called test api, message should be received soon');
  //   let url: string = environment.baseUrl + '/firebase/messaging/test';
  //   url +=
  //     '?encrypted=' +
  //     this.encryptDecryptService.encryptParam(NotificationService.Token);

  //   return this.httpClient.get<any>(url);
  // }
}
