import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/lms/common/common.service';
import { UserService } from 'src/app/lms/users-management/user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private commonService: CommonService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            console.error(err);

            let keycloakApiCorsError: boolean = err.url && err.url.includes(environment.keycloak.url) && !err.method && (err.status === 0);

            if (err.status === 401 || keycloakApiCorsError || err.status === 503) {
                // auto logout if 401 response returned from api
                this.commonService.sessionLogout(true);
            }
            else if (err.status === 404 &&
                (err.url.includes(this.userService.getUserDetailsUrl) || err.url.includes(this.userService.logoutUrl))) {
                this.commonService.sessionLogout(true);
            }
            else if (err.status === 400 && err?.error?.message) {
                this.commonService.matSnackBarOpen(err.error.message);
            }
            else if (err.status === 403) {
                this.commonService.matSnackBarOpenInvalidPermissionMessage();
            }
            else {
                this.commonService.matSnackBarOpenErrorMessage();
            }

            let error = err.error?.message || err.statusText;

            if (err.error instanceof Blob) {
                if (err.status == 400) {
                    error = err.error;
                }
                else if (err.status == 412) {
                    error = "Error: This file is empty";
                } 
                else if(err.status == 422){
                    error = "Error: This file has the wrong format.";
                } 
                else{
                    error = "An unexpected error has been occurred, please try again later'";
                }

            }

            return throwError(error);
        }));
    }
}
