import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomDatasource } from 'src/app/components/custom-data.interface';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';
import { CONTEXTABLE_TYPE, LEAD_TYPE } from '../enum/lms-type.enum';
import { ILeadGroup, ILeadGroupForm } from './interfaces/lead-group.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadGroupService {
  private moduleBaseUrl: string = environment.baseUrl + '/lead-group';

  constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  getAsPage(body: any): Observable<CustomDatasource> {
    const url: string = this.moduleBaseUrl + '/page';
    return this.httpClient.post<CustomDatasource>(url, body);
  }

  getAsListFromContextable(contextableType: CONTEXTABLE_TYPE): Observable<ILeadGroup[]> {
    let type: LEAD_TYPE;

    switch (contextableType) {
      case CONTEXTABLE_TYPE.PERSON:
        type = LEAD_TYPE.INDIVIDUAL;
        break;
      case CONTEXTABLE_TYPE.COMPANY:
        type = LEAD_TYPE.COMPANY;
        break;
    }
    console.log("Type", CONTEXTABLE_TYPE[contextableType])
    return this.getAsList(type);
  }

  getAsList(leadType: LEAD_TYPE): Observable<ILeadGroup[]> {
    const type = LEAD_TYPE[leadType];
    const url: string = this.moduleBaseUrl + '/list' + '?encrypted=' + this.encryptDecryptService.encryptParam(type);
    return this.httpClient.get<ILeadGroup[]>(url);
  }

  get(id: number): Observable<ILeadGroup> {
    const url: string = this.moduleBaseUrl + '?encrypted=' + this.encryptDecryptService.encryptParam(id);
    return this.httpClient.get<ILeadGroup>(url);
  }

  add(body: ILeadGroupForm): Observable<number> {
    return this.httpClient.post<number>(this.moduleBaseUrl, body);
  }

  update(id: number, body: ILeadGroupForm): Observable<number> {
    const url: string = this.moduleBaseUrl + '?encrypted=' + this.encryptDecryptService.encryptParam(id);
    return this.httpClient.put<number>(url, body);
  }

  delete(id: number): Observable<number> {
    const url: string = this.moduleBaseUrl + '?encrypted=' + this.encryptDecryptService.encryptParam(id);
    return this.httpClient.delete<number>(url);
  }
}
