import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from '../common/encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private httpClient: HttpClient, private encryptDecryptService: EncryptDecryptService) {}

  /* Products */

  getProducts(id: number = 0): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/list?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getActiveProducts(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/list/active');
  }

  getActiveProductsForDashboard(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/list/dashboard');
  }

  getProductPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/page', pageableRequest);
  }

  getProductById(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/get?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  createProduct(body: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product', body);
  }

  updateProduct(id: number, body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/update?encrypted=' + this.encryptDecryptService.encryptParam(id), body);
  }

  deleteProduct(id: number): Observable<any> {
    return this.httpClient.delete(environment.baseUrl + '/product/delete?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveCreateProduct(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/approve/create?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveUpdateProduct(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/approve/update?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveDeleteProduct(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/approve/delete?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectProduct(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/reject?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectCreateProduct(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/reject/create?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  rejectUpdateProduct(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/reject/update?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  rejectDeleteProduct(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/product/reject/delete?encrypted=' + this.encryptDecryptService.encryptParam(body.id), body);
  }

  getUsers(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/users/list');
  }

  deleteProductRoute(id: number): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/delete/route?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  saveProductRoute(payload: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/tmp-route', payload);
  }

  getProductRouteAsPage(body): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/page/route', body);
  }

  getTmpProductRouteAsPage(body): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/page/tmp-route', body);
  }

  getTmpProductOwnersAsPage(body): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/page/tmp-owner', body);
  }

  getProductOwnersAsPage(body): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/product/page/owner', body);
  }

  getProductTypeDropdownList(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/type/list');
  }

  getProductListByProductType(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/product/list/productType?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getRouteByDistributionGroup(body): Observable<any> {
    return this.httpClient.post(
      environment.baseUrl + '/product/list/product-route/distribution-group', body
    );
  }

  getTmpRouteByDistributionGroup(id: string): Observable<any> {
    return this.httpClient.get(
      environment.baseUrl + '/product/list/tmp-product-route/distribution-group?encrypted=' + this.encryptDecryptService.encryptParam(id)
    );
  }

  getProductPageByProductType(id: string, pageableRequest: any): Observable<any> {
    return this.httpClient.post(
      environment.baseUrl + '/product/page/productType?encrypted=' + this.encryptDecryptService.encryptParam(id),
      pageableRequest
    );
  }

  /* Products */

  /* Criteria Service */

  getCriteriaPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/criteria/page', pageableRequest);
  }

  getCriteria(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/criteria/criteria?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  createCriteria(body: any): Observable<number> {
    return this.httpClient.post<number>(environment.baseUrl + '/criteria/create', body);
  }

  updateCriteria(id: number, body: any): Observable<number> {
    return this.httpClient.put<number>(environment.baseUrl + '/criteria/update?encrypted=' + this.encryptDecryptService.encryptParam(id), body);
  }

  deleteCriteria(id: number): Observable<any> {
    return this.httpClient.delete(environment.baseUrl + '/criteria/delete/' + this.encryptDecryptService.encryptParam(id));
  }

  approveCreateCriteria(id: number): Observable<number> {
    return this.httpClient.put<number>(environment.baseUrl + '/criteria/approve/create?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveUpdateCriteria(id: number): Observable<number> {
    return this.httpClient.put<number>(environment.baseUrl + '/criteria/approve/update?encrypted=' + this.encryptDecryptService.encryptParam(id), {});
  }

  approveDeleteCriteria(id: number): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/criteria/approve/delete/' + this.encryptDecryptService.encryptParam(id), {});
  }

  rejectCreateCriteria(body: any): Observable<number> {
    return this.httpClient.put<number>(
      environment.baseUrl + '/criteria/reject/create?encrypted=' + this.encryptDecryptService.encryptParam(body.id),
      body
    );
  }

  rejectUpdateCriteria(body: any): Observable<number> {
    return this.httpClient.put<number>(
      environment.baseUrl + '/criteria/reject/update?encrypted=' + this.encryptDecryptService.encryptParam(body.id),
      body
    );
  }

  getAllCriteria(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/criteria');
  }

  testEncriptedPayload(body: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/criteria/test/encoded', { params: body });
  }

  /* Criteria Service */

  /* Distribution Group */

  getAllDistributionGroup(): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/distribution-group');
  }

  getDistributionGroupDetailsPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/distribution-group-user/page', pageableRequest);
  }

  getDistributionGroupPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/distribution-group/page', pageableRequest);
  }

  getUsersByBranchPage(pageableRequest: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/users/page', pageableRequest);
  }

  getDistributionGroupById(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/distribution-group/group?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getDistributionGroupUsersById(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/distribution-group-user/users?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  getTmpDistributionGroupUsersById(id: string): Observable<any> {
    return this.httpClient.get(environment.baseUrl + '/distribution-group-user/tmp?encrypted=' + this.encryptDecryptService.encryptParam(id));
  }

  createDistributionGroupUser(payload: any): Observable<any> {
    return this.httpClient.post(environment.baseUrl + '/distribution-group-user/create', payload);
  }

  updateDistributionGroup(payload: any): Observable<any> {
    return this.httpClient.put(environment.baseUrl + '/distribution-group-user/update', payload);
  }

  approveCreateDistributionGroup(id: number): Observable<any> {
    return this.httpClient.put(
      environment.baseUrl + '/distribution-group/approve/create?encrypted=' + this.encryptDecryptService.encryptParam(id),
      {}
    );
  }

  approveUpdateDistributionGroup(id: number): Observable<any> {
    return this.httpClient.put(
      environment.baseUrl + '/distribution-group/approve/update?encrypted=' + this.encryptDecryptService.encryptParam(id),
      {}
    );
  }

  rejectCreateDistributionGroup(body: any): Observable<any> {
    return this.httpClient.put(
      environment.baseUrl + '/distribution-group/reject/create?encrypted=' + this.encryptDecryptService.encryptParam(body.id),
      body
    );
  }

  rejectUpdateDistributionGroup(body: any): Observable<any> {
    return this.httpClient.put(
      environment.baseUrl + '/distribution-group/reject/update?encrypted=' + this.encryptDecryptService.encryptParam(body.id),
      body
    );
  }

  /* Distribution Group */
}
